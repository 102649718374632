"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Product = /** @class */ (function () {
    function Product() {
        this.additionalInfoAvailable = false;
        this.availability = null;
        this.catalog = null;
        this.codFam = null;
        this.codeCo = null;
        this.isFavorite = false;
        this.isRecurring = false;
        this.itemName = null;
        this.oemFamily = null;
        this.oemMarkerName = null;
        this.oemReferenceCode = null;
        this.oemSubfamily = null;
        this.pictureAvailable = null;
        this.promoType = null;
        this.purchasePrice = null;
        this.quantity = null;
        this.salePrice = null;
        this.itemReferenceCode = null;
        this.itemReferenceId = null;
        this.oemName = null;
        this.manufacturerName = null;
        this.consumablesPaintRubrique = null;
        this.consumablesPaintFamily = null;
        this.consumablesPaintSubfamily = null;
    }
    return Product;
}());
exports.default = Product;
