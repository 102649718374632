"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ViewItemDetailsNormalizersResolver_1 = require("./ViewItemDetailsNormalizersResolver");
var AbstractDataBuilder_1 = require("../../core/AbstractDataBuilder");
var ViewItemDetailsDataBuilder = /** @class */ (function (_super) {
    __extends(ViewItemDetailsDataBuilder, _super);
    function ViewItemDetailsDataBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ViewItemDetailsDataBuilder.create = function () {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new ViewItemDetailsNormalizersResolver_1.default());
        }
        return this.builderInstance;
    };
    return ViewItemDetailsDataBuilder;
}(AbstractDataBuilder_1.default));
exports.default = ViewItemDetailsDataBuilder;
