"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmplitudeProcessor = void 0;
var analytics_browser_1 = require("@amplitude/analytics-browser");
var FeatureFlag_1 = require("../FeatureFlag");
var utilities_1 = require("../utilities");
var AmplitudeProcessor = exports.AmplitudeProcessor = /** @class */ (function () {
    function AmplitudeProcessor() {
    }
    AmplitudeProcessor.init = function () {
        if (true === AmplitudeProcessor.isInit) {
            return;
        }
        var key = sessionStorage.getItem('amp');
        var userId = sessionStorage.getItem('userId');
        if (null !== key && null !== userId) {
            (0, analytics_browser_1.init)(key, '', {
                serverZone: 'EU',
                minIdLength: 0,
            });
            (0, analytics_browser_1.setUserId)(userId);
            AmplitudeProcessor.isInit = true;
        }
    };
    AmplitudeProcessor.track = function (eventName, eventProperties) {
        AmplitudeProcessor.init();
        if (FeatureFlag_1.FeatureFlag.isEnabled()) {
            var lowerCasedValues = (0, utilities_1.lowercaseObjectValues)(eventProperties);
            (0, analytics_browser_1.track)(eventName, lowerCasedValues);
        }
    };
    AmplitudeProcessor.identify = function (userData) {
        AmplitudeProcessor.init();
        if (!FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var lowerCasedUserData = (0, utilities_1.lowercaseObjectValues)(userData);
        var event = new analytics_browser_1.Identify();
        event.setOnce('user_account_mail', lowerCasedUserData.userAccountMail);
        event.setOnce('code_rep', lowerCasedUserData.codeRep);
        event.setOnce('code_co', lowerCasedUserData.codeCo);
        event.setOnce('code_distrib', lowerCasedUserData.codeDistrib);
        event.setOnce('code_erp', lowerCasedUserData.codeErp);
        event.setOnce('erp_name', lowerCasedUserData.erpName);
        event.setOnce('package_distrib', lowerCasedUserData.packageDistrib);
        event.setOnce('type_distrib', lowerCasedUserData.typeDistrib);
        (0, analytics_browser_1.identify)(event);
    };
    AmplitudeProcessor.isInit = false;
    return AmplitudeProcessor;
}());
