"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewCartLayerEvent = /** @class */ (function () {
    function ViewCartLayerEvent(origin) {
        this.origin = origin;
    }
    ViewCartLayerEvent.prototype.export = function () {
        return {
            cart_view_origin: this.origin,
        };
    };
    return ViewCartLayerEvent;
}());
exports.default = ViewCartLayerEvent;
