"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewLaborTimeEvent = /** @class */ (function () {
    function ViewLaborTimeEvent() {
        this.laborOrigin = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
    }
    ViewLaborTimeEvent.prototype.export = function () {
        return {
            labor_origin: this.laborOrigin,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
        };
    };
    return ViewLaborTimeEvent;
}());
exports.default = ViewLaborTimeEvent;
