"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributes = void 0;
var constants_1 = require("../../../constants");
/**
 * update attributes after ajax response
 */
var ProductAttributes = /** @class */ (function () {
    function ProductAttributes() {
    }
    ProductAttributes.fromPrices = function (item, values) {
        var salePrice = values.sale_price !== undefined ? values.sale_price.replace(',', '.') : "--.--";
        var purchasePrice = values.purchase_price !== undefined ? values.purchase_price.replace(',', '.') : "--.--";
        var availability = values.availability !== undefined ? values.availability : "--";
        var quantity = values.quantity;
        var codref = $(item).data('codref');
        var amplitudeDiv = $(item).find('#amplitude-' + codref);
        $(amplitudeDiv).attr(constants_1.ATTR_SALE_PRICE, salePrice).data(constants_1.DATA_SALE_PRICE, salePrice);
        $(amplitudeDiv).attr(constants_1.ATTR_PURCHASE_PRICE, purchasePrice).data(constants_1.DATA_PURCHASE_PRICE, purchasePrice);
        $(amplitudeDiv).attr(constants_1.ATTR_QUANTITY, quantity).data(constants_1.DATA_QUANTITY, quantity);
        $(amplitudeDiv).attr(constants_1.ATTR_AVAILABILITY, availability).data(constants_1.DATA_AVAILABILITY, availability);
    };
    ProductAttributes.fromCheckDispo = function (parent, result) {
        var delaiSimplifie = result.DELAI_SIMPLIFIE !== undefined ? result.DELAI_SIMPLIFIE : "--";
        var parentDiv = $(parent).closest(':has(.amplitude)');
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDiv).attr(constants_1.ATTR_AVAILABILITY, delaiSimplifie).data(constants_1.DATA_AVAILABILITY, delaiSimplifie);
        var quantity = $(parent).val().toString();
        $(amplitudeDiv).attr('data-quantity', quantity).data('quantity', quantity);
    };
    ProductAttributes.fromFavorites = function (element, isFavorite) {
        var parentDiv = $(element).closest(':has(.amplitude)');
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        var amplitudeId = $(amplitudeDiv).attr('id');
        var selector = "";
        selector = selector.concat("[id=", amplitudeId, "]");
        $(selector).each(function (index, value) {
            $(value).attr('data-is-favorite', isFavorite).data('is-favorite', isFavorite);
        });
    };
    ProductAttributes.fromLoadPrice = function (item, value) {
        var salePrice = value.PVHT !== undefined ? value.PVHT.replace(',', '.') : "--.--";
        var purchasePrice = value.PAHT !== undefined ? value.PAHT.replace(',', '.') : "--.--";
        var availability = value.DELAI_SIMPLIFIE !== undefined ? value.DELAI_SIMPLIFIE : "--";
        var quantity = value.QUANTITE;
        var parentDiv = $(item).closest(':has(.amplitude)');
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDiv).attr(constants_1.ATTR_SALE_PRICE, salePrice).data(constants_1.DATA_SALE_PRICE, salePrice);
        $(amplitudeDiv).attr(constants_1.ATTR_PURCHASE_PRICE, purchasePrice).data(constants_1.DATA_PURCHASE_PRICE, purchasePrice);
        $(amplitudeDiv).attr(constants_1.ATTR_QUANTITY, quantity).data(constants_1.DATA_QUANTITY, quantity);
        $(amplitudeDiv).attr(constants_1.ATTR_AVAILABILITY, availability).data(constants_1.DATA_AVAILABILITY, availability);
    };
    ProductAttributes.fromQuoteGetPrice = function (element, result) {
        var amplitudeDiv = $(element).closest('td').find('.amplitude');
        if (1 === $(amplitudeDiv).length) {
            $(amplitudeDiv).attr(constants_1.ATTR_AVAILABILITY, result.delai_simplifie).data(constants_1.DATA_AVAILABILITY, result.delai_simplifie);
        }
    };
    ProductAttributes.fromLayerGetPrice = function (amplitudeDiv, result) {
        if (1 === $(amplitudeDiv).length) {
            $(amplitudeDiv).attr(constants_1.ATTR_AVAILABILITY, result.delai_simplifie).data(constants_1.DATA_AVAILABILITY, result.delai_simplifie);
        }
    };
    ProductAttributes.updateLayerAvailabilityForAmplitude = function (element, amplitudeDiv) {
        var hasQuotationAccess = $('#quotation_access').val();
        if (hasQuotationAccess == 1) {
            var data = {
                codequ: $(element).attr('data-equip'),
                refeq: $(element).attr('data-refeq'),
                typeligne: $(element).attr('data-typeline'),
                qte: $(amplitudeDiv).attr('data-quantity'),
                codfam: $(amplitudeDiv).attr('data-cod-fam')
            };
            return $.ajax({
                url: Routing.generate('autossimo_quotes_get_availabilty'),
                type: "POST",
                data: data,
                async: false,
                success: function (result) {
                    ProductAttributes.fromLayerGetPrice(amplitudeDiv, result);
                }
            });
        }
    };
    return ProductAttributes;
}());
exports.ProductAttributes = ProductAttributes;
