"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var PaintStartSearchNormalizer_1 = require("./PaintStartSearchNormalizer");
var StartSearchEvent_1 = require("./StartSearchEvent");
var ChaineStartSearchNormalizer = /** @class */ (function (_super) {
    __extends(ChaineStartSearchNormalizer, _super);
    function ChaineStartSearchNormalizer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChaineStartSearchNormalizer.prototype.normalize = function (element, origin) {
        var startSearchEvent = new StartSearchEvent_1.default();
        startSearchEvent.catalog = 'chaine';
        startSearchEvent.searchType = 'snow_chain';
        startSearchEvent.searchTerms = this.getSearchTerms(element);
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    };
    ChaineStartSearchNormalizer.prototype.support = function (element, origin) {
        return 'chaine' === origin;
    };
    ChaineStartSearchNormalizer.prototype.getSearchTerms = function (element) {
        if ($(element).hasClass('result'))
            return $(element).data('dimchaine');
        if ($(element).hasClass('reference')) {
            var searchTerms_1 = [];
            $(element).find('span').each(function (i, v) {
                searchTerms_1[i] = $(v).text();
            });
            return searchTerms_1.join(' | ');
        }
        return $('#chainsSocksAutocomplete').val();
    };
    return ChaineStartSearchNormalizer;
}(PaintStartSearchNormalizer_1.default));
exports.default = ChaineStartSearchNormalizer;
