"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var ViewVehicleTechnicalDataEvent = /** @class */ (function () {
    function ViewVehicleTechnicalDataEvent() {
        this.vehicles = [];
        this.technicalOrigin = '';
    }
    ViewVehicleTechnicalDataEvent.prototype.export = function () {
        return {
            technical_origin: this.technicalOrigin,
            vehicles: (0, utilities_1.exportVehicles)(this.vehicles),
        };
    };
    return ViewVehicleTechnicalDataEvent;
}());
exports.default = ViewVehicleTechnicalDataEvent;
