"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var AddToCartEvent = /** @class */ (function () {
    function AddToCartEvent(origin) {
        this.status = false;
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    AddToCartEvent.prototype.setStatus = function (status) {
        this.status = status;
    };
    AddToCartEvent.prototype.export = function () {
        var products = this.productCollection.export();
        if (0 === products.length) {
            return null;
        }
        return {
            cart_add_origin: this.origin,
            status: this.status,
            products: products,
        };
    };
    return AddToCartEvent;
}());
exports.default = AddToCartEvent;
