"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DisplayIamForOemEvent_1 = require("./DisplayIamForOemEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var constants_1 = require("../../constants");
var DisplayIamForOemNormalizer = /** @class */ (function () {
    function DisplayIamForOemNormalizer() {
    }
    DisplayIamForOemNormalizer.prototype.normalize = function (element, origin) {
        if (-1 === constants_1.PATH.indexOf('/pieces/')) {
            return null;
        }
        var displayIamForOemEvent = new DisplayIamForOemEvent_1.default(origin);
        var resultsContainer = $(element).closest('.reference-origine-results');
        resultsContainer = 1 === $(resultsContainer).length ? resultsContainer : $(element).closest('.results-container');
        if (1 !== $(resultsContainer).length) {
            return null;
        }
        var subResult = $(resultsContainer).find('.sub_result');
        if ($(subResult).css('display') === 'none') {
            return null;
        }
        var loading = resultsContainer.find('.loading-progression');
        if (loading.is(':visible')) {
            return null;
        }
        var amplitudeDivs = $(resultsContainer).find('tr:not(.hide) .amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            displayIamForOemEvent.productCollection.addProduct(product);
        });
        displayIamForOemEvent.totalIamForThisOem = $(resultsContainer).find('.choix-equipementiers strong:nth-child(1)').text().trim();
        displayIamForOemEvent.oemReferenceCode = $(resultsContainer).find('.origin_piece strong:nth-child(1)').text().trim();
        displayIamForOemEvent.oemName = $(resultsContainer).find('.caracteristic_piece strong:nth-child(1), #label_oem_ref').text().trim();
        return displayIamForOemEvent;
    };
    DisplayIamForOemNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return DisplayIamForOemNormalizer;
}());
exports.default = DisplayIamForOemNormalizer;
