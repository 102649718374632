"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var RemoveFromCartEvent = /** @class */ (function () {
    function RemoveFromCartEvent(origin) {
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    RemoveFromCartEvent.prototype.export = function () {
        return {
            cart_remove_origin: this.origin,
            products: this.productCollection.export(),
        };
    };
    return RemoveFromCartEvent;
}());
exports.default = RemoveFromCartEvent;
