"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var EditPartQuantityEvent = /** @class */ (function () {
    function EditPartQuantityEvent(origin) {
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    EditPartQuantityEvent.prototype.export = function () {
        return {
            edit_part_quantity_origin: this.origin,
            products: this.productCollection.export(),
        };
    };
    return EditPartQuantityEvent;
}());
exports.default = EditPartQuantityEvent;
