"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchEvent_1 = require("./StartSearchEvent");
var StartSearchByModelNormalizer = /** @class */ (function () {
    function StartSearchByModelNormalizer() {
    }
    StartSearchByModelNormalizer.prototype.normalize = function (element, category) {
        var startSearchEvent = new StartSearchEvent_1.default();
        startSearchEvent.catalog = 'pieces';
        startSearchEvent.searchType = 'recherche par modele';
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.searchTerms = '';
        startSearchEvent.consumablesPaintSubfamily = '';
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    };
    StartSearchByModelNormalizer.prototype.support = function (element, category) {
        return 'searchByModel' === category;
    };
    return StartSearchByModelNormalizer;
}());
exports.default = StartSearchByModelNormalizer;
