"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventDtoTransformer_1 = require("./EventDtoTransformer");
var ChaineStartSearchNormalizer_1 = require("../startSearch/ChaineStartSearchNormalizer");
var ChaineResultsNotFoundNormalizer = /** @class */ (function () {
    function ChaineResultsNotFoundNormalizer() {
        this.chaineStartSearchNormalizer = new ChaineStartSearchNormalizer_1.default();
    }
    ChaineResultsNotFoundNormalizer.prototype.normalize = function (element, category) {
        var startSearchEvent = this.chaineStartSearchNormalizer.normalize(element, category);
        return EventDtoTransformer_1.EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    };
    ChaineResultsNotFoundNormalizer.prototype.support = function (element, category) {
        return this.chaineStartSearchNormalizer.support(element, category);
    };
    return ChaineResultsNotFoundNormalizer;
}());
exports.default = ChaineResultsNotFoundNormalizer;
