"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewCartLayerEvent_1 = require("./ViewCartLayerEvent");
var ViewCartLayoutNormalizer = /** @class */ (function () {
    function ViewCartLayoutNormalizer() {
    }
    ViewCartLayoutNormalizer.prototype.normalize = function (elements, origin) {
        return new ViewCartLayerEvent_1.default(window.location.pathname);
    };
    ViewCartLayoutNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return ViewCartLayoutNormalizer;
}());
exports.default = ViewCartLayoutNormalizer;
