"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AddToCartEvent_1 = require("./AddToCartEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var AddToCartFromQuoteNormalizer = /** @class */ (function () {
    function AddToCartFromQuoteNormalizer() {
    }
    AddToCartFromQuoteNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default(context.origin);
        var amplitudeDivs = $('table.quotes-result--results-list .amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            addToCartEvent.productCollection.addProduct(product);
        });
        addToCartEvent.status = context.status;
        return addToCartEvent;
    };
    AddToCartFromQuoteNormalizer.prototype.support = function (element, context) {
        return "devis entretien" === context.origin;
    };
    return AddToCartFromQuoteNormalizer;
}());
exports.default = AddToCartFromQuoteNormalizer;
