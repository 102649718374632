"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartAddToQuotationEvent_1 = require("./StartAddToQuotationEvent");
var StartAddToQuotationNormalizer = /** @class */ (function () {
    function StartAddToQuotationNormalizer() {
    }
    StartAddToQuotationNormalizer.prototype.normalize = function (elements, origin) {
        var startAddToQuotationEvent = new StartAddToQuotationEvent_1.default(origin);
        startAddToQuotationEvent.productCollection.addProduct(elements);
        return startAddToQuotationEvent;
    };
    StartAddToQuotationNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return StartAddToQuotationNormalizer;
}());
exports.default = StartAddToQuotationNormalizer;
