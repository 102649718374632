"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var ViewLaborTimeEvent_1 = require("./ViewLaborTimeEvent");
var ViewLaborTimeNormalizer = /** @class */ (function () {
    function ViewLaborTimeNormalizer() {
    }
    ViewLaborTimeNormalizer.prototype.normalize = function (element, context) {
        var viewLaborTimeEvent = new ViewLaborTimeEvent_1.default();
        viewLaborTimeEvent.laborOrigin = 'view_item_list';
        viewLaborTimeEvent.family = (0, utilities_1.getFamily)();
        viewLaborTimeEvent.subfamily = (0, utilities_1.getSubFamily)();
        viewLaborTimeEvent.markerName = (0, utilities_1.getLibRep)();
        return viewLaborTimeEvent;
    };
    ViewLaborTimeNormalizer.prototype.support = function (element, context) {
        return true;
    };
    return ViewLaborTimeNormalizer;
}());
exports.default = ViewLaborTimeNormalizer;
