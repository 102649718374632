"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var Flash_1 = require("../../Flash");
var ViewItemResultsEvent_1 = require("./ViewItemResultsEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var TireViewItemResultsNormalizer = /** @class */ (function () {
    function TireViewItemResultsNormalizer() {
    }
    TireViewItemResultsNormalizer.prototype.normalize = function (blockDataLayer, context) {
        var _a, _b, _c, _d, _e, _f;
        var origin = (_a = Flash_1.default.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : ''; // à vérifier
        var viewItemResultsEvent = new ViewItemResultsEvent_1.default();
        var appliedFilters = context.filters.filter(function (item) { return false === ["searchref", "bCount", "page"].includes(item.name); });
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = (0, utilities_1.checkCons)(context.catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = (_c = (0, utilities_1.getFamily)()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.subfamily = (_d = (0, utilities_1.getSubFamily)()) !== null && _d !== void 0 ? _d : '';
        viewItemResultsEvent.markerName = (_e = (0, utilities_1.getLibRep)()) !== null && _e !== void 0 ? _e : '';
        viewItemResultsEvent.filtersApplied = 0 !== appliedFilters.length;
        viewItemResultsEvent.totalIamResults = (_f = $('#afficher').data('nbtires')) !== null && _f !== void 0 ? _f : '';
        viewItemResultsEvent.totalOemResults = '';
        var amplitudeDivs = blockDataLayer.find('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    };
    TireViewItemResultsNormalizer.prototype.support = function (element, context) {
        return 'pneus' === context.catalog;
    };
    return TireViewItemResultsNormalizer;
}());
exports.default = TireViewItemResultsNormalizer;
