"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var CompleteOrderEvent = /** @class */ (function () {
    function CompleteOrderEvent(status, origin) {
        this.status = status;
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    CompleteOrderEvent.prototype.export = function () {
        return {
            origin: this.origin,
            status: this.status,
            orders: this.orders,
            total_orders_amount: this.totalOrdersAmount,
            products: this.productCollection.export(),
        };
    };
    return CompleteOrderEvent;
}());
exports.default = CompleteOrderEvent;
