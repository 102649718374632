import { trimString } from "./pubtech-javascriptf";
import { majMontantPanier } from './pubtech-panier'
import { getFicheProduitAjax } from "./pubtech-main-v2";
import $ from "jquery";
import {
    animatePanierButton,
    handleAjaxResult,
    handleProductExistsInCart,
    preAddToCartCheck, preAddToCartPunchout,
    preAddToCartSingleProductDataCollect
} from "./pre-add-to-cart";
import EventHandler from "./analytics/amplitude/EventHandler";
import { initImgWithLazyLoading } from "./init-images-carousel";
import { callAjaxToLoadPrice, formatRefeqParamsForErpRequest } from "./pubtech-dispo-price";
import { updateListRefEquip } from "./pubtech-vehicule-affectation-origine";
import GaEventHandler from "./analytics/ga/GaEventHandler";

var LABEL_DATACODMAR = 'data-codmar';
var EQUIPEMENTIERS_SELECTOR = '#equipementiers';
var CONSTRUCTORS_SELECTOR = '#nbConstructeurs';
var SELECT_EQUIPEMENTIERS_SELECTOR = 'select.selectEquipementiers';
var ORIGINDATAS_SELECTOR = '.produits > table > tbody > tr.origin-datas';
var LISTCODMAR_SELECTOR = 'data-listcodmar';
var SELECTED_LABEL = ' sélectionnés';
var EQUIVALENT_ORIGIN_SELECTOR = '.produits > table > tbody > tr.origin-equivalence';
var DATAEQUIP_LABEL = 'data-equip';
const POP_IN_CORA_ALERT_INFOS = 'autossimo_pop_in_cora_alert_infos';
const TARGET_CORA_UNAVAILABLE = 'popInCoraAlertInfos';
const isFiducial = $('.user-infos').data('partner-name') === 'FIDUCIAL';
const dataAvailability = 'data-availability';
const SCROLL_PERCENTAGE_VALUE = 0.95;
const REFERENCES_LOADING_ID = '#references-loading';

$(document).ready(function () {
    /* Recherche par Ref. Origine */
    //Filtre constructeur
    $(document).on('change', 'select.selectMarques', function () {
        var codmar = $(this).val();

        if (codmar == "tous") {
            organizeTableDisplay();

            changeDisplayVisibleEquipment();
            $(EQUIPEMENTIERS_SELECTOR).selectpicker('refresh');

            $(CONSTRUCTORS_SELECTOR).html($(this).find('option[value!="tous"]').not('.hide').length);
        } else {
            if ($(SELECT_EQUIPEMENTIERS_SELECTOR).val() === null) {
                organizeProductsDisplayNoSelectedEquipmentierss(codmar);
            } else {
                organizeProductsDisplay(codmar);
            }

            changeDisplayVisibleEquipment();
            $(EQUIPEMENTIERS_SELECTOR).selectpicker('refresh');

            $(CONSTRUCTORS_SELECTOR).html('1');
        }

        loadTriEquips();

        $('#nbRefEquipVal').html($(ORIGINDATAS_SELECTOR).not('.hide').length);
        $('#refequipNb').html($(ORIGINDATAS_SELECTOR).not('.hide').length);
    });

    //Filtre equipementier
    addOnChangeListenerEquipmentiers();

    /* Recherche par Ref. Equipementier */

    // N'affiche que les refs. equipementiers visibles
    // avant chargement du reste suite au scroll
    var listVisibleEquip = [];
    $(EQUIVALENT_ORIGIN_SELECTOR).each(function () {
        listVisibleEquip.push($(this).attr(DATAEQUIP_LABEL));
    });

    $('#equipementiersEquiv option').each(function () {
        if ($(this).val() !== 'tous') {
            if ($.inArray($(this).val(), listVisibleEquip) === -1) {
                $(this).addClass('hide');
            } else {
                $(this).removeClass('hide');
            }
        }
    });

    try {
        $('#equipementiersEquiv').selectpicker('refresh');
    } catch (e) {
        console.log('selectpicker is not defined');
    }


    addOnChangeListenerEquivalentEquipmentiers();

    // appel popin fiche produit piece
    $(document).on('click', 'div.fiche-produit > a', function () {
        var path = $(this).data("path");
        getFicheProduitAjax(path);
    });

    addOnClickListenerCartPiece();

    if ($("div.replacements-ref").length > 0) {
        loadReplacements();
    }
});

function loadTriEquips() {
    if ($('.triEquips option:selected').length > 1) {
        $('.triEquips button').attr('title', $('.triEquips option:not(.hide):selected').length);
        $('.triEquips button > span:first-child').html($('.triEquips option:not(.hide):selected').length + SELECTED_LABEL);
    }
}

function changeDisplayVisibleEquipment() {
    $('#equipementiers option').each(function () {
        $(this).removeClass('hide');
    });
}

function organizeProductsDisplayNoSelectedEquipmentierss(codmar) {
    $(ORIGINDATAS_SELECTOR).each(function () {
        if (!$(this).attr(LISTCODMAR_SELECTOR).includes(codmar)) {
            $(this).addClass('hide');
        } else {
            $(this).removeClass('hide');
        }
    });
}

function organizeProductsDisplay(codmar) {
    $(ORIGINDATAS_SELECTOR).each(function () {
        var codequip = $(this).attr(DATAEQUIP_LABEL);
        if (!$(this).attr(LISTCODMAR_SELECTOR).includes(codmar) && $.inArray(codequip, $(SELECT_EQUIPEMENTIERS_SELECTOR).val()) !== -1 && $(this).hasClass('hide') === false) {
            $(this).addClass('hide');
        } else if (!$(this).attr(LISTCODMAR_SELECTOR).includes(codmar) && $.inArray('tous', $(SELECT_EQUIPEMENTIERS_SELECTOR).val()) !== -1 && $(this).hasClass('hide') === false) {
            $(this).addClass('hide');
        } else if ($(this).attr(LISTCODMAR_SELECTOR).includes(codmar) && $.inArray(codequip, $(SELECT_EQUIPEMENTIERS_SELECTOR).val()) !== -1 && $(this).hasClass('hide') === true) {
            $(this).removeClass('hide');
        }
    });
}

function addOnClickListenerCartPiece() {
    $(document).on('click', '.add-cart-piece', function (e) {
        if ($(this).closest('.folder-disabled-btn').length > 0) {
            return false;
        }
        $(this).prop('disabled', true);
        const isOrder = $('.results.detail-commande');
        const productSheetStatus = $('#popinFicheProduit').css('display');
        let origin = 'item_view_results';
        if (isOrder.length > 0 || productSheetStatus === 'block') {
            origin = 'item_view';
        }
        GaEventHandler.addToCart($(this), 'piece');

        if (($(this).data('manufacturer-code') === 'CORA') && ($(this).attr(dataAvailability) === 'A') && !isFiducial) {
            EventHandler.addToCart($(this), origin, false);
            getPopin($(this), Routing.generate(POP_IN_CORA_ALERT_INFOS), TARGET_CORA_UNAVAILABLE);
        } else if ('block' === $(this).parents('.v3_pricing').find('.availability-pricing:not(.mode) .bloc-contact-your-distributor.with-contact-us').css('display')
            || $(this).parents('.v3_pricing').find('.available_on_demand').length > 0) {
            EventHandler.addToCart($(this), origin, false);
            getPopin($(this), Routing.generate('autossimo_pop_in_available_on_demand'), 'popInAvailableOnDemand');
        } else {
            e.preventDefault();
            addProductToCart($(this), origin);
        }

    });
}

/**
 * Function let to add the requested item to the cart.
 */
function addProductToCart(element, origin) {
    let quantity = 1;
    if (element.hasClass('ps-cart-btn')) {
        quantity = $("#QFP-" + element.data("codrefeq")).length > 0 ? $("#QFP-" + element.data("codrefeq")).val() : $("#Q-" + element.data("codrefeq")).val();
    } else {
        quantity = $("#Q-" + element.data("codrefeq")).length > 0 ? $("#Q-" + element.data("codrefeq")).val() : $("#QFP-" + element.data("codrefeq")).val();
    }
    const params = {
        obj: element,
        dataProductId: element.data("product-id"),
        ajaxurl: element.data("path"),
        codrefco: element.data("codrefco"),
        codrefeq: element.data("codrefeq"),
        qte: quantity,
        typeligne: element.attr("data-type-ligne"),
        date: (typeof element.attr("data-date") !== 'undefined') ? element.attr("data-date") : null,
        paramDropshipping: null,
        manufacturerCode: element.attr('data-manufacturer-code'),
        availability: element.attr(dataAvailability),
        description: element.attr('data-description') ? element.attr('data-description') : '',
        refeq: element.attr('data-refeq-cart'),
        vehiculeImmat: element.attr('data-vehicule-immat'),
        advertisingBannerId: element.data('advertising-banner-id'),
    }
    ajouterPanier(params, origin);
}

function organizeTableDisplay() {
    var hideReference = false;
    var constructeurUnique = [];
    var comptMarque = 0;
    $(ORIGINDATAS_SELECTOR).each(function () {
        var codequip = $(this).attr(DATAEQUIP_LABEL);
        if (
            ($(this).hasClass('hide') &&
                ($(SELECT_EQUIPEMENTIERS_SELECTOR).val() === 'tous' || !$(SELECT_EQUIPEMENTIERS_SELECTOR).val())) ||
            ($(this).hasClass('hide') && $.inArray(codequip, $(SELECT_EQUIPEMENTIERS_SELECTOR).val()) !== -1)
        ) {
            $(this).removeClass('hide');
        }

        var constructeurChecked = [
            $(this).attr(DATAEQUIP_LABEL),
            $(this).attr('data-refequip'),
            $(this).attr('data-codrefeq')
        ];
        comptMarque++;

        if (comptMarque == 1) {
            constructeurUnique.push([
                $(this).attr(DATAEQUIP_LABEL),
                $(this).attr('data-refequip'),
                $(this).attr('data-codrefeq')
            ]);
        } else {
            for (var i = 0, len = constructeurUnique.length; i < len; i++) {
                if (constructeurUnique[i][0] == constructeurChecked[0] && constructeurUnique[i][1] == constructeurChecked[1] && constructeurUnique[i][2] == constructeurChecked[2]) {
                    hideReference = true;
                    $(this).addClass('hide');
                    break;
                }
            }
            if (!hideReference) {
                constructeurUnique.push(constructeurChecked);
            }
            hideReference = false;
        }
    });
}


function addOnChangeListenerEquipmentiers() {
    $(document).on('change', SELECT_EQUIPEMENTIERS_SELECTOR, function () {
        var refEquipSelected = $(this).val();
        var listVisibleConstruct = [];

        if (!refEquipSelected) {
            processSelectedBrandsForEquipementiersListener();

            changeDisplayVisibleEquipment();
            $(EQUIPEMENTIERS_SELECTOR).selectpicker('refresh');
        } else {
            processSelectedBrands(refEquipSelected)

            if (refEquipSelected.length > 1) {
                var nbEquipSelected = 0;
                for (var i = 0; i < refEquipSelected.length; i++) {
                    if ($(this).find('option[value="' + refEquipSelected[i] + '"]').hasClass('hide') === false) {
                        nbEquipSelected++;
                    }
                }
                $('.triEquips button').attr('title', nbEquipSelected + SELECTED_LABEL);
                $('.triEquips button > span:first-child').html(nbEquipSelected + SELECTED_LABEL);
            }
        }
        $(CONSTRUCTORS_SELECTOR).html(listVisibleConstruct.length);
        $('#nbRefEquipVal').html($(ORIGINDATAS_SELECTOR).not('.hide').length);
        $('#refequipNb').html($(ORIGINDATAS_SELECTOR).not('.hide').length);
    });
}

function processSelectedBrandsForEquipementiersListener() {
    if ($('select.selectMarques').val() !== 'tous') {
        $('tr.origin-datas').each(function () {
            $(this).removeClass('hide');
            if (!$(this).attr(LISTCODMAR_SELECTOR).includes($('select.selectMarques').val())) {
                $(this).addClass('hide');
            }
        });
    } else {
        var listEquip = [];
        $('tr.origin-datas').each(function () {
            $(this).removeClass('hide');
            var attributes = $(this).attr(DATAEQUIP_LABEL) + '-' + $(this).attr('data-refequip');
            $(this).addClass('hide');
            if ($.inArray(attributes, listEquip) === -1) {
                listEquip.push(attributes);
                $(this).removeClass('hide');
            }
        });
    }
}

function processSelectedBrands(refEquipSelected) {
    if ($('select.selectMarques').val() == 'tous') {
        processNbEquipSelected(refEquipSelected);
    } else {
        $(ORIGINDATAS_SELECTOR).each(function () {
            var element = $(this);
            var hasFilteredEquip = refEquipSelected.some(function (value) {
                return element.attr(DATAEQUIP_LABEL) === value;
            });
            if (!hasFilteredEquip && !$(this).attr(LABEL_DATACODMAR).includes($('select.selectMarques').val())) {
                $(this).addClass('hide');
            } else if (!hasFilteredEquip && $(this).attr(LABEL_DATACODMAR).includes($('select.selectMarques').val())) {
                $(this).addClass('hide');
            } else if (hasFilteredEquip && $(this).attr(LABEL_DATACODMAR).includes($('select.selectMarques').val())) {
                $(this).removeClass('hide');
            }
        });
    }
}

function processNbEquipSelected(refEquipSelected) {
    var nbEquipSelected = refEquipSelected.length;
    var nbEquip = $('select.selectEquipementiers option').length;
    var listEquip = [];
    if (nbEquipSelected == nbEquip) {
        $('tr.origin-datas').each(function () {
            $(this).removeClass('hide');
            var attributes = $(this).attr(DATAEQUIP_LABEL) + '-' + $(this).attr('data-refequip');
            if ($.inArray(attributes, listEquip) === -1) {
                listEquip.push(attributes);
            } else {
                $(this).addClass('hide');
            }
        });
    } else {
        $(ORIGINDATAS_SELECTOR).each(function () {
            var dataEquip = $(this).attr(DATAEQUIP_LABEL);
            var hasFilteredEquip = refEquipSelected.some(function (value) {
                return dataEquip === value;
            });
            var attributes = $(this).attr(DATAEQUIP_LABEL) + '-' + $(this).attr('data-refequip');
            if (!hasFilteredEquip || $.inArray(attributes, listEquip) !== -1) {
                $(this).addClass('hide');
            } else {
                listEquip.push(attributes);
                $(this).removeClass('hide');
            }
        });
    }
}


function addOnChangeListenerEquivalentEquipmentiers() {
    $(document).on('change', '#equipementiersEquiv', function () {
        if (!$(this).val()) {
            $(EQUIVALENT_ORIGIN_SELECTOR).removeClass('hide');
        } else {
            processNbEquipSelected($(this).val())
            $(EQUIVALENT_ORIGIN_SELECTOR).each(function () {
                var hasFilteredEquip = $(this).val().some(function (value) {
                    return $(this).attr(DATAEQUIP_LABEL) === value;
                });

                if (!hasFilteredEquip) {
                    $(this).addClass('hide');
                } else {
                    $(this).removeClass('hide');
                }
            });
            if ($(this).val().length > 1) {
                var nbEquipSelected = 0;
                for (var i = 0; i < $(this).val().length; i++) {
                    if ($(this).find('option[value="' + $(this).val()[i] + '"]').hasClass('hide') === false) {
                        nbEquipSelected++;
                    }
                }
                $('.filtreEquipementiersEquiv button').attr('title', nbEquipSelected + SELECTED_LABEL);
                $('.filtreEquipementiersEquiv button > span:first-child').html(nbEquipSelected + SELECTED_LABEL);
            }

        }

        $('.nb-refs-iam').html($(EQUIVALENT_ORIGIN_SELECTOR).not('.hide').length);
    });
}

/* Retourne la liste des equipementiers visibles après filtre
 * afin de mettre à jour la liste des équipementiers */
function getVisibleEquip() {
    var listEquip = [];
    $(ORIGINDATAS_SELECTOR).not('.hide').each(function () {
        var equip = $(this).attr(DATAEQUIP_LABEL);
        if ($.inArray(equip, listEquip) === -1) {
            listEquip.push(equip);
        }
    });
    listEquip.sort();

    return listEquip;
}

function getFamillesAssocieesHtml(obj, dataProductId, ajaxurl) {
    if (0 == jQuery('tr.famille-associee-content[data-product="' + dataProductId + '"] td').length) {
        jQuery('tr.famille-associee-content[data-product="' + dataProductId + '"]').load(ajaxurl, function () { });
        $('tr.famille-associee-content[data-product="' + dataProductId + '"]').show();
    }
}


function validerAjoutDevis(obj, formDevisId, ajaxurl, codrefeq, codrefco, qte, typeligne) {
    jQuery.ajax({
        type: "POST",
        data: { codrefco: codrefco, codrefeq: codrefeq, typeligne: typeligne, qte: qte, type: jQuery('#' + formDevisId + ' input:checked').val() },
        url: ajaxurl,
        async: false,
    });
}

function preProcessCartParams(cartParams) {
    if (typeof cartParams.date === 'undefined') {
        cartParams.date = null;
    }
    if (typeof cartParams.manufacturerCode === 'undefined') {
        cartParams.manufacturerCode = null;
    }
    if (typeof cartParams.availability === 'undefined') {
        cartParams.availability = null;
    }
    if (typeof cartParams.refeq === 'undefined') {
        cartParams.refeq = null;
    }
    if (!cartParams.description) {
        cartParams.description = '';
    }
}

export function ajouterPanier(cartParams, origin = '') {
    if (cartParams.obj.closest('.folder-disabled-btn').length > 0) {
        return false;
    }

    preProcessCartParams(cartParams)

    const source = getSourceValue(cartParams);
    // test class is equal to btn-cart
    if (!cartParams.obj.hasClass('btn-cart-selected')) {
        if (typeof cartParams.paramDropshipping === 'undefined' || cartParams.paramDropshipping === null) {
            cartParams.paramDropshipping = {
                flag_dropshipping: 'N',
                siret_dropshipping: ''
            };
        }
        if (cartParams.paramDropshipping.siret_dropshipping === 'N') {
            cartParams.paramDropshipping.siret_dropshipping = ''
        }
        const preAddCheck = preAddToCartCheck(preAddToCartSingleProductDataCollect(cartParams, source), false);
        if (handleAjaxResult(preAddCheck)) {
            handleProductExistsInCart(preAddCheck, function () {
                const resultSend = sendToCart(cartParams, source);
                EventHandler.addToCart($(cartParams.obj), origin, resultSend);
            },
                $(cartParams.obj),
                () => EventHandler.addToCart($(cartParams.obj), origin, false))
        } else {
            const resultSend = sendToCart(cartParams, source);
            EventHandler.addToCart($(cartParams.obj), origin, resultSend);
            if (resultSend) {
                animatePanierButton($(cartParams.obj))
            }
        }
    }

    return true;
}

function sendToCart(cartParams, source) {
    // check if we are in punchout or not
    const isPunchout = $('input#isPunchout').val();
    let result = true;
    if (isPunchout) {
        const productId = cartParams.dataProductId;
        const quantity = cartParams.qte;
        result = preAddToCartPunchout(productId, quantity, source);
    }
    // make ajax request to add to cart
    if (result) {
        ajouterPanierAjax(cartParams, source);
        if (!cartParams.obj.hasClass('cora-article-not-available')) {
            setTimeout(function () {
                processDropShippingFlag(cartParams);
            }, 2000);
        }
    }

    return result;
}

export function ajouterPanierAjax(cartParams, source) {
    const dataToSend = preAddToCartSingleProductDataCollect(cartParams, source)
    jQuery.ajax({
        type: "POST",
        data: dataToSend[0],
        url: cartParams.ajaxurl,
        async: false,
        success: function (data) {     // define call back function
            if (data['success']) {
                if (cartParams.paramDropshipping.flag_dropshipping === 'O') {
                    $('tr.quantity-dropshipping #add-pneu-cart-' + cartParams.codrefeq).hide();
                    $('tr.quantity-dropshipping #validate-add-cart-' + cartParams.codrefeq).show();
                } else {
                    $('#add-pneu-cart-' + cartParams.codrefeq).hide();
                    $('#validate-add-cart-' + cartParams.codrefeq).show();
                }
                majMontantPanier(data['nbarticles'], data['montant']);
            } else if ((!data['success']) && (cartParams.manufacturerCode === 'CORA') &&
                (cartParams.availability === 'A') && !isFiducial) {
                getPopin($(this), Routing.generate(POP_IN_CORA_ALERT_INFOS), TARGET_CORA_UNAVAILABLE);
            }
        }, // end function
        onFailure: function (data) {      // define call back function
        } // end function
    });
}

function processDropShippingFlag(cartParams) {
    if (cartParams.paramDropshipping.flag_dropshipping === 'O') {
        $('tr.quantity-dropshipping #add-pneu-cart-' + cartParams.codrefeq).show();
        $('tr.quantity-dropshipping #validate-add-cart-' + cartParams.codrefeq).hide();

    } else {
        $('#add-pneu-cart-' + cartParams.codrefeq).show();
        $('#validate-add-cart-' + cartParams.codrefeq).hide();
    }
}

function getSourceValue(cartParams) {
    var source = null;
    if (cartParams.from !== undefined && cartParams.from === 1) {
        source = "IDG";
    }

    if (cartParams.platform !== undefined && cartParams.platform === 'DIPC') {
        source = "DIPC";
    }

    return source;
}

export function updateBasket(ajaxurl, qte, numligne, blockQte) {
    const forbidden = 'Vous n\'êtes pas autorisé à effectuer cette opération';

    var blockPrice = $(blockQte).closest('.custom_margin_right').parent().find('div.selectPriceLevel');
    var idPriceLevel = blockPrice.find(':selected').attr('data-id');
    var price = blockPrice.find('span').text().split('€')[0];
    var isPriceLevel = $(blockQte).closest('.custom_margin_right').parent().find('.price-level-bloc').is(':visible');

    const CART_ITEM = '.section_cart--body-item';
    let netPrice = $(blockPrice).parents(CART_ITEM).find('.net-price.net-price-rate-ttc').html();
    let rawPrice = $(blockPrice).parents(CART_ITEM).find('.ht-price.rate-ht').html();
    let rawPriceTTC = $(blockPrice).parents(CART_ITEM).find('.ttc-price').html();

    netPrice = (netPrice.toString().replace('€ HT', '') !== '--,-- ') ?
        netPrice.toString().replace('€ HT', '') : 0;
    rawPrice = (rawPrice.toString().replace('€ HT', '') !== '--,-- ') ?
        rawPrice.toString().replace('€ HT', '') : 0;
    rawPriceTTC = (rawPriceTTC.toString().replace('€ TTC', '') !== '--,-- ') ?
        rawPriceTTC.toString().replace('€ TTC', '') : 0;

    $.ajax({
        type: "POST",
        data: {
            numligne: numligne,
            quantite: qte,
            isPriceLevel: isPriceLevel,
            price: trimString(price),
            idPriceLevel: idPriceLevel,
            netPrice: netPrice,
            rawPriceTTC: rawPriceTTC,
            rawPrice: rawPrice,
        },
        url: ajaxurl,
        async: false,
        success: function (data) {     // define call back function
            $('.basket-price').html(data['nbarticles']);
            $('.basket-htprice').html(data['montantHt'].replace('.', ','));
            $('.basket-ttcprice').html(data['montantTtc'].replace('.', ','));
            $('.basket-htpricevente').html(data['montantVenteHt'].replace('.', ','));
            $('.basket-ttcpricevente').html(data['montantVenteTtc'].replace('.', ','));
            // regenerate basket xml
            regenerateBasketXml();
        }, // end function
        onFailure: function (data) {      // define call back function
        }, // end function
        error: function (error) {
            switch (error.status) {
                case 403:
                    displayCartModal(forbidden);
                    break;
                default:
                    break;
            }
        }
    });
}

/**
 * regenerate the basket xml content in DOM by refreshing corresponding iframe.
 */
function regenerateBasketXml() {
    $('#postHookUrl, #postHookUrlLayer').attr('src', function (i, val) {
        return val;
    });
}

function filterEquipOrigine(reforigine, codequip, lientout) {
    $('div.produits[reforigine=' + reforigine + '] tr.ligne-espace').show();
    $('div.produits[reforigine=' + reforigine + '] tr.complements-produit').show();
    $('div.produits[reforigine=' + reforigine + '] tr.panier-input').show();

    if (codequip != 'tout') {
        $('div.produits[reforigine=' + reforigine + '] tr[data-equip!=' + codequip + ']').hide();
    } else if (lientout != '') {
        window.location.href = lientout;
    }
}

/**
 * Ajout panier multiple pour la page sidexa layoutSidexa.html.twig
 * Appelé par pubtech-panier.js
 */
export function ajouterPanierSidexa(isIdgPartener) {
    var from = "STANDARD";
    if (isIdgPartener === 1) {
        from = "IDG";
    }
    $('a.cart-opener span.nbPanier').attr('data-last', $('a.cart-opener span.nbPanier').html()); // mise à jour nb articles panier avant ajout
    let data = {
        cat: [],
        horsCat: []
    }; // datas pour ajax
    var countCoraAviability = 0;
    var dataCoraNotAvailable = [];
    const elements = $('form .checkbox1 input[type=radio]:checked').not('form.disabled-ref .checkbox1 input[type=radio]');
    $.each(
        $(elements), function (index, value) { // recueil données ts articles en catalogue
            var selection = $(value).parents('.panier-input');
            var availability = selection.find('center[data-availability]').attr(dataAvailability);
            if ((selection.attr(DATAEQUIP_LABEL) === 'CORA') && availability === 'A') {
                dataCoraNotAvailable.push({
                    'designation': selection.find('.caracteristiques-details.td-sidexa2-ref-sized td').eq(0).text(),
                    'referenceIAM': selection.find('.ref').text(),
                    'equipment': selection.find('.equipementier-details.td-sidexa2-ref-sized td').eq(0).text()
                });
                countCoraAviability++;
            } else {
                data.cat.push({
                    codrefco: selection.find('.codrefco').val(),
                    codrefeq: selection.find('.codrefeq').val(),
                    typeligne: 'R',
                    refcon: selection.find('.refco').val(),
                    qte: selection.find('.cart-action input').val(),
                    refeq: selection.find('.refeq-cart').val(),
                    manufacturerCode: selection.find('.manufacturer-code').val(),
                    origin: CDEorigin.SIDEXA,
                    from: from,
                    availability: availability
                });
            }
        });
        
    GaEventHandler.addToCart($(elements), 'sidexa');
    if (countCoraAviability > 0 && !isFiducial) {
        getPopin($(this), Routing.generate('autossimo_pop_in_cora_alert_infos'), 'popInCoraAlertInfos', '', dataCoraNotAvailable);
    }
    $.each($('form .refOHC').not('form.disabled-ref .refOHC'), function () { // idem hors cat
        data.horsCat.push({
            codequ: '',
            design: $(this).find('.libpiece').html(),
            refeq: $(this).attr('data-ref'),
            qte: $(this).attr('data-qte'),
            refcon: $(this).find('.refco').val(),
            prix: $(this).attr('data-ht'),
            tx: $(this).attr('data-txtva'),
            origin: CDEorigin.SIDEXA,
            from: from
        });
    });
    const preAddCheck = preAddToCartCheck(data.cat, false);
    if (handleAjaxResult(preAddCheck)) {
        handleProductExistsInCart(preAddCheck, function () {
            addToCartIDG(data)
        }, null)
    } else {
        addToCartIDG(data)
    }
}

function addToCartIDG(data) {
    $.ajax({
        type: 'POST',
        data: data,
        url: AJOUT_PANIER_SIDEXA_AJAX,
        success: function (r) {
            $('a.cart-opener span.nbPanier').html(r['nbarticles']); // màj nb articles
            $('span.ajaxError').hide();
        },
        error: function () {
            $('span.ajaxError').show();
        },
        complete: function () { // remet icone ds ts cas
            $('.btn-cart span').css('display', 'inline-block');
            $('.btn-cart img.spinner').hide();
        }
    });
}

/**
 *
 * @param data
 * @param sort
 * @param order
 */
function sortRefeqInRefOrigAndRefEquipBy(data, sort, order) {
    var sortDiv = [];
    $(data).each(function (index, value) {
        var valueSort = $(this).attr('data-' + sort);
        if (sort === 'price') {
            if (valueSort === '' || valueSort === 0 || valueSort === '--' || valueSort === '--,--') {
                valueSort = '0';
            }

            valueSort = valueSort.replace(",", ".");
            if ($.isNumeric(valueSort)) {
                valueSort = parseFloat(valueSort);
            }
        }
        sortDiv.push({ key: index, value: valueSort });
    });

    if (order === 'desc') {
        sortDiv.sort(function (a, b) {
            if (a.value > b.value) {
                return -1;
            }
            else if (a.value < b.value) {
                return 1;
            }
            return 0;
        });
    } else {
        sortDiv.sort(function (a, b) {
            if (a.value < b.value) {
                return -1;
            }
            else if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    }

    return sortDiv;
}

function sortDispo(data, order) {
    var sortDiv = [];
    var dispoToDisplay = [];
    if (order === 'asc') {
        dispoToDisplay = ['D', 'C', 'P', 'F', 'E', 'I', 'A', 'N'];
    } else {
        dispoToDisplay = ['I', 'N', 'A', 'I', 'E', 'F', 'P', 'C', 'D'];
    }

    for (var i = 0; i < dispoToDisplay.length; i++) {
        $(data).each(function (index, value) {
            var valueSort = $(this).attr('data-dispo');
            var valuePrice = ($(this).attr('data-price') == '' || $(this).attr('data-price') == 0 || $(this).attr('data-price') == '--') ? '0' : $(this).attr('data-price');
            valuePrice = parseFloat(valuePrice);
            if (valueSort === dispoToDisplay[i]) {
                sortDiv.push({ key: index, value: valueSort });
            }
        });
    }

    return sortDiv;
}

$('.search-car-sort .arrows').on("click", function () {
    var data = $('.origin-equivalence.origin-datas');
    var dataTri = $(this).find('a').attr('data-tri');
    dataTri = dataTri.split("_");
    var sort = dataTri[0];
    var order = dataTri[1];
    var divList = sortRefeqInRefOrigAndRefEquipBy(data, sort, order);
    divList.forEach(function (value) {
        $("#table-content").append($(data[value.key]));
    });
    return false;
});

//Tri ref équipementiers dans les résultats recherche pièces
$(document).on("click", '.ref-equip-sort .arrows', function () {

    var triSelected = $(this);
    var data = $(this).closest('.produits').find('.origin-equivalence.origin-datas');
    var dataTri = $(this).children('a').attr('data-tri');

    dataTri = dataTri.split("_");
    var sort = dataTri[0];
    var order = dataTri[1];

    var divList = [];
    if (sort === 'dispo') {
        divList = sortDispo(data, order);
    } else {
        divList = sortRefeqInRefOrigAndRefEquipBy(data, sort, order);
    }

    divList.forEach(function (value) {
        triSelected.closest('table').find('tbody').first().append($(data[value.key]));
    });

    return false;
});


$(document).on('click', '.logiciels_auto  a.enregister', function () {
    var softwareCode = $('#software_software').val();
    var csrfToken = $('#software__token').val();
    $.ajax({
        type: 'POST',
        url: '/moncompte/logiciels',
        data: {
            softwareCode: softwareCode,
            csrfToken: csrfToken
        },
        success: function (data) {
            if (data.result == true) {
                $("#modalLogiciel").modal();
                $('#modalLogiciel .saved_msg').removeClass('hide');

            } else {
                $("#modalLogiciel").modal();
                $('#modalLogiciel .error_msg').removeClass('hide');
            }
        },
        error: function (error) {
            if (error.status === 422) {
                $('.required_software').removeClass('hidden');
            } else {
                $('.required_software').addClass('hidden');
                $("#modalLogiciel").modal();
                $('#modalLogiciel .error_msg').removeClass('hide');
            }
        }
    })
});

$(document).on('click', 'a.save_price_mode', function () {
    var modePrice = $('#toggle_currency_mode_action:hidden:first').prop('checked');

    var priceModeValue = 0;

    if (modePrice === true) {
        priceModeValue = 1;
    }

    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_moncompte_price_mode'),
        data: {
            mode: priceModeValue
        },
        success: function (data) {
            if (data.result === 1) {
                $("#modalLogiciel").modal();
                $('#modalLogiciel .saved_msg').removeClass('hide');

            } else {
                $("#modalLogiciel").modal();
                $('#modalLogiciel .error_msg').removeClass('hide');
            }
        },
        error: function () {
            $("#modalLogiciel").modal();
            $('#modalLogiciel .error_msg').removeClass('hide');
        }

    })
});

$(document).on('click', '#close', function () {
    $(this).closest('.modal').hide();
});

function getNbrReferencesBasket() {
    jQuery.ajax({
        type: "POST",
        url: Routing.generate('autossimo_panier_get_number_of_references'),
        success: function (data) {
            if (data['success']) {
                $('a.cart-opener span.nbPanier').html(data['nbarticles']);
            }
        }, // end function
    });
}

/**
 * function load the replacement block via an Ajax call
 */
export function loadReplacements(codRefeqs = null) {
    const divReplacements = $('div.replacements-ref');
    let referencesDatas = [];
    if (codRefeqs === null) {
        divReplacements.each(function () {
            if (this.hasAttribute('data-codrefeq')) {
                referencesDatas.push($(this).attr('data-codrefeq'));
            }
        });
    } else {
        referencesDatas = codRefeqs;
    }
    if (referencesDatas.length > 0) {
        const advertisingBannerId = $('#advertising-banner-id').val() || '';
        jQuery.ajax({
            type: "POST",
            data: {
                references: referencesDatas.join(','),
                advertisingBannerId: advertisingBannerId
            },
            url: Routing.generate('autossimo_ajax_get_replacements'),
            async: true,
            success: function (data) {
                var updatedRef = data.data;
                jQuery.each(updatedRef, function (i, value) {
                    const replacementDiv = `div.replacements-ref[data-codrefeq="${i}"]`;
                    $(replacementDiv).html(value);
                    $(`${replacementDiv} .product-link`).unbind().click(function () {
                        getFicheProduitAjax($(this).data('product-url'));
                    });
                });

            },
            onFailure: function (error) {
                console.log('error', error);
            }
        });
    }
}
$(document).on("click", "form[name='sales_coefficient'] input[type='submit']", function (e) {
    $('#sales_coefficient_Coefvente').each(function () {
        var salesCoeficient = $(this).val().replace(',', '.');
        var checkSalesCoeficient = $.isNumeric(salesCoeficient)
            && salesCoeficient > 0
            && salesCoeficient.indexOf('+') < 0 && salesCoeficient.indexOf(' ') < 0 || salesCoeficient === '';
        if (!checkSalesCoeficient) {
            e.preventDefault();
            $(this).addClass('required');
            $(this).parent().parent().parent().parent().find('.required_error').show();
        } else {
            $(this).removeClass('required');
            $(this).parent().parent().parent().parent().find('.required_error').hide();
        }
    });
});
let isLoadingPrices = false;

$(document).ready(function () {
    if ($('#source-type').attr('data-source') === 'equivalence') {
        $(window).scroll(function () {
            const scrollTop           = $(document).scrollTop();
            const windowHeight        = $(window).height();
            const bodyHeight          = $(document).height() - windowHeight;
            const scrollPercentage    = (scrollTop / bodyHeight);
            if (scrollPercentage > SCROLL_PERCENTAGE_VALUE && isLoadingPrices === false) {
                isLoadingPrices = true;
                const totalItemsCount     = parseInt($('#infos-generales').attr('data-nbresults'));
                const visibleItemsCount   = parseInt($('#current-count-references').attr('data-current-count'));
                if (totalItemsCount > visibleItemsCount) {
                    referenceListSession();
                    updateListRefEquip();
                } else {
                    $(REFERENCES_LOADING_ID).addClass('hidden');
                }
            }
        });
    }
});

function referenceListSession() {
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_equivalent_references_session'),
        async: true,
        beforeSend: function() {
            $(REFERENCES_LOADING_ID).removeClass('hidden');
        },
        success: function (data) {
            $('.origin-equivalence #table-content').append(data);
            const references = $('.references-produit-list .produits .origin-datas:not(.priceLoaded,.data-tyre) .containerDispo');
            const referencesDatas = [];
            let countRef = 0;
            references.each(function () {
                const ref = $(this);
                if (ref.find('.loader-dispo-price').css('display') !== 'none') {
                    const formatRef = formatRefeqParamsForErpRequest(ref);
                    referencesDatas.push(formatRef);
                    countRef++;
                }
            });
            const currentCountReferences= $('#current-count-references');
            countRef += parseInt(currentCountReferences.attr('data-current-count'));
            currentCountReferences.attr('data-current-count', countRef);
            if (referencesDatas.length > 0) {
                updateEquipementiersEquivOptions();
                callAjaxToLoadPrice(referencesDatas);
                $('.references-produit-list .produits .origin-datas:not(.priceLoaded,.data-tyre)').addClass('priceLoaded');
            }
            initImgWithLazyLoading();
        },
        complete: function () {
            $(REFERENCES_LOADING_ID).addClass('hidden');
            isLoadingPrices = false;
        },
    });
}

function updateEquipementiersEquivOptions() {
    const listVisibleEquip = [];
    $(EQUIVALENT_ORIGIN_SELECTOR).each(function(){
        listVisibleEquip.push($(this).attr(DATAEQUIP_LABEL));
    });

    $('#equipementiersEquiv option').each(function(){
        if ($(this).val() !== 'tous') {
            if ($.inArray($(this).val(), listVisibleEquip) === -1) {
                $(this).addClass('hide');
            } else {
                $(this).removeClass('hide');
            }
        }
    });

    try{
        $('#equipementiersEquiv').selectpicker('refresh');
    }catch(e) {
        console.log('selectpicker is not defined');
    }
}
