"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserData = /** @class */ (function () {
    function UserData() {
        this.userAccountMail = '';
        this.codeRep = '';
        this.codeCo = '';
        this.codeDistrib = '';
        this.codeErp = '';
        this.erpName = '';
        this.packageDistrib = '';
        this.typeDistrib = '';
    }
    UserData.prototype.export = function () {
        return this;
    };
    return UserData;
}());
exports.default = UserData;
