"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var ViewVehicleResultsEvent = /** @class */ (function () {
    function ViewVehicleResultsEvent() {
        this.catalog = '';
        this.searchType = '';
        this.immatInput = false;
        this.resultsMultipleVehicles = false;
        this.searchTerms = '';
        this.vehicles = [];
    }
    ViewVehicleResultsEvent.prototype.export = function () {
        return {
            catalog: this.catalog,
            search_type: this.searchType,
            immat_input: this.immatInput,
            results_multiple_vehicles: this.resultsMultipleVehicles,
            search_terms: this.searchTerms,
            vehicles: (0, utilities_1.exportVehicles)(this.vehicles),
        };
    };
    return ViewVehicleResultsEvent;
}());
exports.default = ViewVehicleResultsEvent;
