"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var PdfQuotationEvent_1 = require("./PdfQuotationEvent");
var PdfQuotationNormalizer = /** @class */ (function () {
    function PdfQuotationNormalizer() {
    }
    PdfQuotationNormalizer.prototype.normalize = function (elements, origin) {
        var pdfQuotationEvent = new PdfQuotationEvent_1.default();
        var amplitudeDivs = $(constants_1.AMPLITUDE_CLASS);
        if (0 === amplitudeDivs.length) {
            return pdfQuotationEvent;
        }
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            pdfQuotationEvent.productCollection.addProduct(product);
        });
        return pdfQuotationEvent;
    };
    PdfQuotationNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return PdfQuotationNormalizer;
}());
exports.default = PdfQuotationNormalizer;
