"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserData_1 = require("./UserData");
var IdentifyUserNormalizer = /** @class */ (function () {
    function IdentifyUserNormalizer() {
    }
    IdentifyUserNormalizer.prototype.normalize = function (dataUser, origin) {
        var userData = new UserData_1.default();
        userData.userAccountMail = dataUser['emailrep'];
        userData.codeRep = dataUser['codrep'];
        userData.codeCo = dataUser['codrepCom'];
        userData.codeDistrib = dataUser['codeDistrib'];
        userData.codeErp = dataUser['codErp'];
        userData.erpName = dataUser['usererp'];
        userData.packageDistrib = dataUser['packageDistrib'];
        userData.typeDistrib = dataUser['distribType'];
        return userData;
    };
    IdentifyUserNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return IdentifyUserNormalizer;
}());
exports.default = IdentifyUserNormalizer;
