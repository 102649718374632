"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var manage_ga_1 = require("../../../manage-ga");
var constants_1 = require("../../constants");
var utilities_1 = require("../../utilities");
var ViewVehicleResultsEvent_1 = require("./ViewVehicleResultsEvent");
var DefaultNormalizer = /** @class */ (function () {
    function DefaultNormalizer() {
    }
    DefaultNormalizer.prototype.normalize = function (blockDataLayer, category) {
        if (0 === $(constants_1.BLOCK).length) {
            return null;
        }
        var vehicles = (0, utilities_1.getVehiclesData)(blockDataLayer);
        var searchType = (0, utilities_1.getSearchType)(blockDataLayer);
        var viewVehicleResultsEvent = new ViewVehicleResultsEvent_1.default();
        viewVehicleResultsEvent.catalog = (0, utilities_1.checkCons)(category);
        viewVehicleResultsEvent.searchType = searchType;
        viewVehicleResultsEvent.resultsMultipleVehicles = $(constants_1.BLOCK).length > 1 && !(0, manage_ga_1.checkBandAlert)();
        viewVehicleResultsEvent.immatInput = constants_1.inputSearch.hasClass('has-immatriculation');
        viewVehicleResultsEvent.searchTerms = (0, manage_ga_1.getSearchTerm)(blockDataLayer);
        viewVehicleResultsEvent.vehicles = vehicles;
        return viewVehicleResultsEvent;
    };
    DefaultNormalizer.prototype.support = function (blockData, category) {
        return constants_1.PATH.indexOf('pieces/resultats') >= 0 || constants_1.PATH.indexOf('pieces/resultats-recherche') >= 0;
    };
    return DefaultNormalizer;
}());
exports.default = DefaultNormalizer;
