"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ResultsNotFoundNormalizer_1 = require("./ResultsNotFoundNormalizer");
var PaintResultsNotFoundNormalizer_1 = require("./PaintResultsNotFoundNormalizer");
var TireResultsNotFoundNormalizer_1 = require("./TireResultsNotFoundNormalizer");
var ConsumableResultsNotFoundNormalizer_1 = require("./ConsumableResultsNotFoundNormalizer");
var AbstractNormalizersResolver_1 = require("../../core/AbstractNormalizersResolver");
var ChaineResultsNotFoundNormalizer_1 = require("./ChaineResultsNotFoundNormalizer");
var ResultsNotFoundNormalizersResolver = /** @class */ (function (_super) {
    __extends(ResultsNotFoundNormalizersResolver, _super);
    function ResultsNotFoundNormalizersResolver() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ResultsNotFoundNormalizersResolver.prototype.register = function () {
        return [
            new ResultsNotFoundNormalizer_1.default(),
            new PaintResultsNotFoundNormalizer_1.default(),
            new TireResultsNotFoundNormalizer_1.default(),
            new ConsumableResultsNotFoundNormalizer_1.default(),
            new ChaineResultsNotFoundNormalizer_1.default(),
        ];
    };
    return ResultsNotFoundNormalizersResolver;
}(AbstractNormalizersResolver_1.default));
exports.default = ResultsNotFoundNormalizersResolver;
