"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductFactory = void 0;
var Product_1 = require("./Product");
var ProductFactory = /** @class */ (function () {
    function ProductFactory() {
    }
    ProductFactory.createFromDataAttribute = function (element) {
        var productDto = new Product_1.default();
        productDto.catalog = element.data('catalog');
        productDto.itemReferenceCode = element.data('item-reference-code');
        productDto.itemName = element.data('item-name');
        productDto.itemReferenceId = element.data('item-reference-id');
        productDto.salePrice = element.data('sale-price');
        productDto.availability = element.data('availability');
        productDto.purchasePrice = element.data('purchase-price');
        productDto.quantity = element.data('quantity');
        productDto.promoType = element.data('promo-type');
        productDto.pictureAvailable = element.data('picture-available');
        productDto.additionalInfoAvailable = element.data('additional-info-available');
        productDto.isFavorite = element.data('is-favorite');
        productDto.isRecurring = element.data('is-recurring');
        productDto.oemReferenceCode = element.data('oem-reference-code');
        productDto.oemName = element.data('oem-name');
        productDto.oemFamily = element.data('oem-family');
        productDto.oemSubfamily = element.data('oem-subfamily');
        productDto.oemMarkerName = element.data('oem-marker-name');
        productDto.manufacturerName = element.data('manufacturer-name');
        productDto.codFam = element.data('cod-fam');
        productDto.codeCo = element.data('code-co');
        productDto.consumablesPaintRubrique = element.data('consumables-paint-rubrique');
        productDto.consumablesPaintFamily = element.data('consumables-paint-family');
        productDto.consumablesPaintSubfamily = element.data('consumables-paint-subfamily');
        return productDto;
    };
    ProductFactory.createFromJsonString = function (productString) {
        var productDto = new Product_1.default();
        try {
            var productObject = JSON.parse(productString);
            productDto.catalog = productObject.catalog;
            productDto.itemReferenceCode = productObject.itemReferenceCode;
            productDto.itemName = productObject.itemName;
            productDto.itemReferenceId = productObject.itemReferenceId;
            productDto.salePrice = productObject.salePrice;
            productDto.availability = productObject.availability;
            productDto.purchasePrice = productObject.purchasePrice;
            productDto.quantity = productObject.quantity;
            productDto.promoType = productObject.promoType;
            productDto.pictureAvailable = productObject.pictureAvailable;
            productDto.additionalInfoAvailable = productObject.additionalInfoAvailable;
            productDto.isFavorite = productObject.isFavorite;
            productDto.isRecurring = productObject.isRecurring;
            productDto.oemReferenceCode = productObject.oemReferenceCode;
            productDto.oemName = productObject.oemName;
            productDto.manufacturerName = productObject.manufacturerName;
            productDto.oemFamily = productObject.oemFamily;
            productDto.oemSubfamily = productObject.oemSubfamily;
            productDto.oemMarkerName = productObject.oemMarkerName;
            productDto.codFam = productObject.codFam;
            productDto.codeCo = productObject.codeCo;
            productDto.consumablesPaintRubrique = productObject.consumablesPaintRubrique;
            productDto.consumablesPaintFamily = productObject.consumablesPaintFamily;
            productDto.consumablesPaintSubfamily = productObject.consumablesPaintSubfamily;
        }
        catch (err) {
            // For syntax error
        }
        return productDto;
    };
    return ProductFactory;
}());
exports.ProductFactory = ProductFactory;
