"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MatchingResultsEvent_1 = require("./MatchingResultsEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var TireNormalizer = /** @class */ (function () {
    function TireNormalizer() {
    }
    TireNormalizer.prototype.normalize = function (element, context) {
        var matchingResultsEvent = new MatchingResultsEvent_1.default();
        var isLoadingBlock = element.find('.compatible-loading-block');
        matchingResultsEvent.isLoading = isLoadingBlock && 0 !== isLoadingBlock.length;
        var amplitudeDivs = element.find('.containerDispo .amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            matchingResultsEvent.productCollection.addProduct(product);
        });
        return matchingResultsEvent;
    };
    TireNormalizer.prototype.support = function (element, context) {
        return 'pneus' === context.catalog;
    };
    return TireNormalizer;
}());
exports.default = TireNormalizer;
