"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var CompleteOrderEvent_1 = require("./CompleteOrderEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var CompleteOrderNormalizer = /** @class */ (function () {
    function CompleteOrderNormalizer() {
    }
    CompleteOrderNormalizer.prototype.normalize = function (elements, origin) {
        var completeOrderEvent = new CompleteOrderEvent_1.default(elements, origin);
        var amplitudeDivs = $(constants_1.AMPLITUDE_CLASS);
        if (0 === amplitudeDivs.length) {
            return completeOrderEvent;
        }
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            completeOrderEvent.productCollection.addProduct(product);
        });
        completeOrderEvent.orders = this.getOrders();
        var totalOrderAmount = completeOrderEvent.orders.reduce(function (accumulator, order) {
            return accumulator + parseFloat(order.order_amount);
        }, 0);
        completeOrderEvent.totalOrdersAmount = Math.floor(totalOrderAmount * 100) / 100;
        return completeOrderEvent;
    };
    CompleteOrderNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    CompleteOrderNormalizer.prototype.getOrders = function () {
        var orders = [];
        var table = $('#table-order-distri');
        if (table.length) {
            orders.push(this.getTableData(table, 'distri'));
        }
        table = $('#table-order-idg');
        if (table.length) {
            orders.push(this.getTableData(table, 'idg'));
        }
        table = $('#table-order-cora');
        if (table.length) {
            orders.push(this.getTableData(table, 'cora'));
        }
        table = $('#table-order-drop');
        if (table.length) {
            orders.push(this.getTableData(table, 'drop'));
        }
        table = $('#table-order-diproconnect');
        if (table.length) {
            orders.push(this.getTableData(table, 'diproconnect'));
        }
        return orders;
    };
    CompleteOrderNormalizer.prototype.getTableData = function (table, type) {
        var _a;
        return {
            order_type: type,
            order_number: $(table).data('numcde'),
            order_amount: $(table).data('total-amount'),
            movex_transaction_id: (_a = $(table).data('movex-orno')) !== null && _a !== void 0 ? _a : null
        };
    };
    return CompleteOrderNormalizer;
}());
exports.default = CompleteOrderNormalizer;
