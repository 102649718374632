"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var ViewVehicleTechnicalDataEvent_1 = require("./ViewVehicleTechnicalDataEvent");
var ViewVehicleTechnicalDataNormalizer = /** @class */ (function () {
    function ViewVehicleTechnicalDataNormalizer() {
    }
    ViewVehicleTechnicalDataNormalizer.prototype.normalize = function (element, origin) {
        var viewVehicleTechnicalDataEvent = new ViewVehicleTechnicalDataEvent_1.default();
        viewVehicleTechnicalDataEvent.vehicles = (0, utilities_1.getVehiclesData)(element.vehicleDataBlock, null, origin);
        viewVehicleTechnicalDataEvent.technicalOrigin = origin;
        return viewVehicleTechnicalDataEvent;
    };
    ViewVehicleTechnicalDataNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return ViewVehicleTechnicalDataNormalizer;
}());
exports.default = ViewVehicleTechnicalDataNormalizer;
