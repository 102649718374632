"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchEvent_1 = require("./StartSearchEvent");
var pubtech_main_v2_1 = require("../../../pubtech-main-v2");
var SearchTypeMapper_1 = require("./SearchTypeMapper");
var utilities_1 = require("../../utilities");
var Flash_1 = require("../../Flash");
var StartSearchNormalizer = /** @class */ (function () {
    function StartSearchNormalizer() {
    }
    StartSearchNormalizer.prototype.normalize = function (element, category) {
        // jquery elements
        var searchInputElement = element.find('input#search');
        var immatSpanElement = element.find('.jsSearchImmatriculation');
        // const/vars
        var immat = immatSpanElement.text();
        var inputSearchValue = searchInputElement.val();
        var startSearchEvent = new StartSearchEvent_1.default();
        var fullSearchTerms;
        startSearchEvent.catalog = 'pieces';
        startSearchEvent.searchType = element.find('#select-choice').text();
        if (['Recherche Express', 'Immat.'].includes(startSearchEvent.searchType)) {
            fullSearchTerms = this.handleImmat(startSearchEvent, immat, inputSearchValue);
        }
        else if ('Multi Réf Equipementier' === startSearchEvent.searchType) {
            fullSearchTerms = this.handleMultiRefEquipementier(element);
        }
        else {
            fullSearchTerms = inputSearchValue;
        }
        this.handleReferencesAndFamily(startSearchEvent, inputSearchValue);
        startSearchEvent.searchTerms = fullSearchTerms;
        startSearchEvent.searchType = SearchTypeMapper_1.default.get(startSearchEvent.searchType);
        Flash_1.default.setPieceCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    };
    StartSearchNormalizer.prototype.support = function (element, category) {
        return 'pieces' === category;
    };
    StartSearchNormalizer.prototype.handleMultiRefEquipementier = function (element) {
        var searchTerms = [];
        element.find('input.ref-equipmentier').each(function (i, reference) {
            if ('' !== $(reference).val()) {
                searchTerms.push($(reference).val());
            }
        });
        return searchTerms.join(', ');
    };
    StartSearchNormalizer.prototype.handleReferencesAndFamily = function (startSearchEvent, inputSearchValue) {
        var _a, _b, _c, _d;
        var foundItem = (0, utilities_1.getSelectedFamily)(inputSearchValue);
        if (null === foundItem) {
            startSearchEvent.searchMarker = false;
            return;
        }
        var typeRefMapping = [];
        typeRefMapping['re'] = 'IAM';
        typeRefMapping['ro'] = 'OEM';
        startSearchEvent.searchMarker = true;
        startSearchEvent.referenceType = (_a = typeRefMapping[foundItem.typeRef]) !== null && _a !== void 0 ? _a : '';
        startSearchEvent.markerName = (_b = foundItem.family) !== null && _b !== void 0 ? _b : '';
        if ('Résultats références' === foundItem.category) {
            startSearchEvent.referenceCode = (_c = foundItem.label) !== null && _c !== void 0 ? _c : '';
        }
        else {
            startSearchEvent.referenceCode = (_d = foundItem.ref) !== null && _d !== void 0 ? _d : '';
        }
    };
    StartSearchNormalizer.prototype.handleImmat = function (startSearchEvent, immat, inputSearchValue) {
        var searchTerms = [];
        if ('' !== immat.trim()) {
            searchTerms.push(immat);
            if (false !== (0, pubtech_main_v2_1.is_immatriculation)(immat + ' ')) {
                startSearchEvent.immatInput = true;
            }
        }
        if ('' !== inputSearchValue.trim() && false !== (0, pubtech_main_v2_1.is_immatriculation)(inputSearchValue + ' ')) {
            startSearchEvent.immatInput = true;
            searchTerms.push(immat);
        }
        searchTerms.push(inputSearchValue);
        return searchTerms.join(' ');
    };
    return StartSearchNormalizer;
}());
exports.default = StartSearchNormalizer;
