"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchNormalizer_1 = require("../startSearch/StartSearchNormalizer");
var EventDtoTransformer_1 = require("./EventDtoTransformer");
var utilities_1 = require("../../utilities");
var Flash_1 = require("../../Flash");
var ResultsNotFoundNormalizer = /** @class */ (function () {
    function ResultsNotFoundNormalizer() {
        this.startSearchNormalizer = new StartSearchNormalizer_1.default();
    }
    ResultsNotFoundNormalizer.prototype.normalize = function (element, category) {
        var blockDataLayer = (0, utilities_1.getGADataBlock)();
        var vehicles = (0, utilities_1.getVehiclesData)(blockDataLayer);
        if (0 !== vehicles.length) {
            return null;
        }
        var startSearchEvent = Flash_1.default.getPieceCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.startSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer_1.EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    };
    ResultsNotFoundNormalizer.prototype.support = function (element, category) {
        return this.startSearchNormalizer.support(element, category);
    };
    return ResultsNotFoundNormalizer;
}());
exports.default = ResultsNotFoundNormalizer;
