"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SearchTypeMapper = /** @class */ (function () {
    function SearchTypeMapper() {
    }
    SearchTypeMapper.getMappings = function () {
        return {
            'Recherche Express': 'recherche express',
            'Immat.': 'immat',
            'VIN': 'vin',
            'MINE/CNIT': 'mine-cnit',
            'Réf Constructeur': 'ref constructeur',
            'Réf Equipementier': 'ref equipementier',
            'Multi Réf Equipementier': 'multi ref equipementier',
            'Recherche par Modèle': 'recherche par modele',
            'Recherche par arborescence': 'recherche par arborescence',
            'Recherche par Essentiels': 'recherche par essentiels',
            'Recherche par mots-clés': 'recherche par texte',
        };
    };
    SearchTypeMapper.get = function (searchType) {
        var _a;
        return (_a = this.getMappings()[searchType]) !== null && _a !== void 0 ? _a : '';
    };
    return SearchTypeMapper;
}());
exports.default = SearchTypeMapper;
