"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventDtoTransformer_1 = require("./EventDtoTransformer");
var TireStartSearchNormalizer_1 = require("../startSearch/TireStartSearchNormalizer");
var TireResultsNotFoundNormalizer = /** @class */ (function () {
    function TireResultsNotFoundNormalizer() {
        this.tireStartSearchNormalizer = new TireStartSearchNormalizer_1.default();
    }
    TireResultsNotFoundNormalizer.prototype.normalize = function (element, category) {
        var startSearchEvent = this.tireStartSearchNormalizer.normalize(element, category);
        return EventDtoTransformer_1.EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    };
    TireResultsNotFoundNormalizer.prototype.support = function (element, category) {
        return this.tireStartSearchNormalizer.support(element, category);
    };
    return TireResultsNotFoundNormalizer;
}());
exports.default = TireResultsNotFoundNormalizer;
