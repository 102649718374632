"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var ViewItemPictureEvent = /** @class */ (function () {
    function ViewItemPictureEvent(origin) {
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    ViewItemPictureEvent.prototype.export = function () {
        return {
            view_item_picture_origin: this.origin,
            catalog: this.catalog,
            products: this.productCollection.export(),
        };
    };
    return ViewItemPictureEvent;
}());
exports.default = ViewItemPictureEvent;
