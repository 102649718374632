"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var StartOrderEvent_1 = require("./StartOrderEvent");
var StartOrderNormalizer = /** @class */ (function () {
    function StartOrderNormalizer() {
    }
    StartOrderNormalizer.prototype.normalize = function (elements, origin) {
        var startOrderEvent = new StartOrderEvent_1.default(origin);
        var parentDiv = $(elements).closest('#layerPanier');
        if (0 === parentDiv.length) {
            return startOrderEvent;
        }
        var amplitudeDivs = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
            // const amplitudeDivParentDiv = $(amplitudeDiv).closest('.lineItem');
            // ProductAttributes.updateLayerAvailabilityForAmplitude(amplitudeDivParentDiv, amplitudeDiv).done(() => {
            //     const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            //     startOrderEvent.productCollection.addProduct(product);
            // });
        });
        return startOrderEvent;
    };
    StartOrderNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return StartOrderNormalizer;
}());
exports.default = StartOrderNormalizer;
