"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VehicleFactory_1 = require("../dto/vehicle/VehicleFactory");
var ViewVehicleResultsEvent_1 = require("./ViewVehicleResultsEvent");
var ByModelNormalizer = /** @class */ (function () {
    function ByModelNormalizer() {
    }
    ByModelNormalizer.prototype.normalize = function (blockDataLayer, context) {
        var vehicles = [];
        var elements = blockDataLayer;
        if (0 === elements.length) {
            return null;
        }
        elements.each(function (index, value) {
            vehicles.push(VehicleFactory_1.VehicleFactory.createFromDataModel($(value).find('.name a')));
        });
        var viewVehicleResultsEvent = new ViewVehicleResultsEvent_1.default();
        viewVehicleResultsEvent.catalog = 'pieces';
        viewVehicleResultsEvent.searchType = 'recherche par modele';
        viewVehicleResultsEvent.resultsMultipleVehicles = elements.length > 1;
        viewVehicleResultsEvent.immatInput = false;
        viewVehicleResultsEvent.searchTerms = null;
        viewVehicleResultsEvent.vehicles = vehicles;
        return viewVehicleResultsEvent;
    };
    ByModelNormalizer.prototype.support = function (blockDataLayer, context) {
        return 'searchModel' === context;
    };
    return ByModelNormalizer;
}());
exports.default = ByModelNormalizer;
