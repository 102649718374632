"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var PdfQuotationEvent = /** @class */ (function () {
    function PdfQuotationEvent() {
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    PdfQuotationEvent.prototype.export = function () {
        return {
            products: this.productCollection.export(),
        };
    };
    return PdfQuotationEvent;
}());
exports.default = PdfQuotationEvent;
