"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ViewCampaignDetailEvent_1 = require("./ViewCampaignDetailEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var ViewCampaignDetailNormalizer = /** @class */ (function () {
    function ViewCampaignDetailNormalizer() {
    }
    ViewCampaignDetailNormalizer.prototype.normalize = function (elements, origin) {
        var viewCampaignDetail = new ViewCampaignDetailEvent_1.default($('.produits.portal-promo-header').data('promo'));
        var amplitudeDivs = $(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewCampaignDetail.productCollection.addProduct(product);
        });
        return viewCampaignDetail;
    };
    ViewCampaignDetailNormalizer.prototype.support = function (elements, origin) {
        return true;
    };
    return ViewCampaignDetailNormalizer;
}());
exports.default = ViewCampaignDetailNormalizer;
