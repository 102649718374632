"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ApplyFiltersEvent_1 = require("./ApplyFiltersEvent");
var TiresApplyFiltersNormalizer = /** @class */ (function () {
    function TiresApplyFiltersNormalizer() {
    }
    TiresApplyFiltersNormalizer.prototype.normalize = function (element, context) {
        var _a, _b;
        var applyFiltersEvent = new ApplyFiltersEvent_1.default();
        applyFiltersEvent.catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : '';
        var mapping = {
            // tires tab
            'marque': 'brand',
            'gamme': 'range',
            'type de vehicule': 'vehicle type',
            'saison': 'season',
            'charge': 'load',
            'vitesse': 'speed',
            'runflat': 'runflat',
            // chains tab
            'manufacturer_type': 'equipment type',
            'vehicle_type': 'vehicle type',
            'manufacturer': 'brand',
        };
        applyFiltersEvent.filtersCategories = (_b = mapping[element.data('type').toLowerCase()]) !== null && _b !== void 0 ? _b : 'NOT FOUND';
        var values = element.find('option:selected');
        var filterValues = [];
        if (values && values.length) {
            values.each(function (i, option) {
                filterValues.push($(option).text().replace(/[\r\n]/gm, '').trim());
            });
            applyFiltersEvent.filtersValues = filterValues.join(', ');
        }
        return applyFiltersEvent;
    };
    TiresApplyFiltersNormalizer.prototype.support = function (element, context) {
        return ['pneus', 'chaine'].includes(context.catalog);
    };
    return TiresApplyFiltersNormalizer;
}());
exports.default = TiresApplyFiltersNormalizer;
