"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var ApplyFiltersEvent_1 = require("./ApplyFiltersEvent");
var ApplyFiltersNormalizer = /** @class */ (function () {
    function ApplyFiltersNormalizer() {
    }
    ApplyFiltersNormalizer.prototype.normalize = function (element, context) {
        var _a, _b, _c, _d, _e;
        var applyFiltersEvent = new ApplyFiltersEvent_1.default();
        applyFiltersEvent.catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : '';
        applyFiltersEvent.filtersCategories = (_b = context.filterType) !== null && _b !== void 0 ? _b : '';
        applyFiltersEvent.family = (_c = (0, utilities_1.getFamily)()) !== null && _c !== void 0 ? _c : '';
        applyFiltersEvent.subfamily = (_d = (0, utilities_1.getSubFamily)()) !== null && _d !== void 0 ? _d : '';
        applyFiltersEvent.markerName = (_e = (0, utilities_1.getLibRep)()) !== null && _e !== void 0 ? _e : '';
        var selectedEquipementiers = [];
        if ($('#selectEquip').length) {
            $('#selectEquip option:selected').each(function (i, option) {
                selectedEquipementiers.push($(option).text());
            });
        }
        else if ($('#equipementiers').length) {
            $('#equipementiers option:selected').each(function (i, option) {
                selectedEquipementiers.push($(option).text());
            });
        }
        applyFiltersEvent.manufacturerBrand = selectedEquipementiers.join((', '));
        return applyFiltersEvent;
    };
    ApplyFiltersNormalizer.prototype.support = function (element, context) {
        return 'pieces' === context.catalog;
    };
    return ApplyFiltersNormalizer;
}());
exports.default = ApplyFiltersNormalizer;
