"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var ViewItemPictureEvent_1 = require("./ViewItemPictureEvent");
var ViewItemPictureNormalizer = /** @class */ (function () {
    function ViewItemPictureNormalizer() {
    }
    ViewItemPictureNormalizer.prototype.normalize = function (element, origin) {
        var viewItemPictureEvent = new ViewItemPictureEvent_1.default(origin);
        var parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return viewItemPictureEvent;
        }
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return viewItemPictureEvent;
        }
        var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
        viewItemPictureEvent.catalog = product.catalog;
        viewItemPictureEvent.productCollection.addProduct(product);
        return viewItemPictureEvent;
    };
    ViewItemPictureNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return ViewItemPictureNormalizer;
}());
exports.default = ViewItemPictureNormalizer;
