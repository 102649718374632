"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var ViewCampaignDetailEvent = /** @class */ (function () {
    function ViewCampaignDetailEvent(campaignJson) {
        this.campaign = campaignJson;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    ViewCampaignDetailEvent.prototype.export = function () {
        return {
            campaign: this.campaign,
            products: this.productCollection.export(),
        };
    };
    return ViewCampaignDetailEvent;
}());
exports.default = ViewCampaignDetailEvent;
