"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchEvent_1 = require("./StartSearchEvent");
var TireStartSearchNormalizer = /** @class */ (function () {
    function TireStartSearchNormalizer() {
    }
    TireStartSearchNormalizer.prototype.normalize = function (element, origin) {
        var startSearchEvent = new StartSearchEvent_1.default();
        startSearchEvent.catalog = 'pneus';
        startSearchEvent.searchType = 'recherche par texte';
        startSearchEvent.searchTerms = element.find('input#pneusAutocomplete').val();
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    };
    TireStartSearchNormalizer.prototype.support = function (element, origin) {
        return 'pneus' === origin;
    };
    return TireStartSearchNormalizer;
}());
exports.default = TireStartSearchNormalizer;
