"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchEvent_1 = require("./StartSearchEvent");
var Flash_1 = require("../../Flash");
var Context_1 = require("../../Context");
var PaintStartSearchNormalizerByEssentials = /** @class */ (function () {
    function PaintStartSearchNormalizerByEssentials() {
    }
    PaintStartSearchNormalizerByEssentials.prototype.normalize = function (element, category) {
        var startSearchEvent = new StartSearchEvent_1.default();
        var dataLabel = element.data('label');
        startSearchEvent.catalog = 'peintures';
        if ($('.consommablesnav').hasClass('active')) {
            startSearchEvent.catalog = 'consommables';
        }
        startSearchEvent.searchType = 'recherche par essentiels';
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.searchTerms = dataLabel;
        startSearchEvent.consumablesPaintSubfamily = dataLabel;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        Context_1.default.setLastConsumablesPaintSubfamily(startSearchEvent.consumablesPaintSubfamily);
        Flash_1.default.setPaintCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    };
    PaintStartSearchNormalizerByEssentials.prototype.support = function (element, origin) {
        return 'paints_essentials' === origin;
    };
    return PaintStartSearchNormalizerByEssentials;
}());
exports.default = PaintStartSearchNormalizerByEssentials;
