"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultsNotFoundEvent = void 0;
var ResultsNotFoundEvent = /** @class */ (function () {
    function ResultsNotFoundEvent() {
        this.catalog = '';
        this.searchType = '';
        this.immatInput = false;
        this.searchTerms = '';
        this.searchMarker = false;
        this.referenceType = '';
        this.referenceCode = '';
        this.markerName = '';
        this.consumablesPaintSubfamily = '';
    }
    ResultsNotFoundEvent.prototype.export = function () {
        return {
            catalog: this.catalog,
            search_type: this.searchType,
            immat_input: this.immatInput,
            search_terms: this.searchTerms,
            search_marker: this.searchMarker,
            reference_type: this.referenceType,
            reference_code: this.referenceCode,
            marker_name: this.markerName,
            consumables_paint_subfamily: this.consumablesPaintSubfamily,
        };
    };
    return ResultsNotFoundEvent;
}());
exports.ResultsNotFoundEvent = ResultsNotFoundEvent;
