"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var DisplayIamForOemEvent = /** @class */ (function () {
    function DisplayIamForOemEvent(origin) {
        this.oemReferenceCode = '';
        this.oemName = '';
        this.totalIamForThisOem = '';
        this.products = [];
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    DisplayIamForOemEvent.prototype.export = function () {
        return {
            oem_reference_code: this.oemReferenceCode,
            oem_name: this.oemName,
            total_iam_for_this_oem: this.totalIamForThisOem,
            products: this.productCollection.export(),
        };
    };
    return DisplayIamForOemEvent;
}());
exports.default = DisplayIamForOemEvent;
