"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var StartOrderEvent_1 = require("./StartOrderEvent");
var StartOrderForFolderNormalizer = /** @class */ (function () {
    function StartOrderForFolderNormalizer() {
    }
    StartOrderForFolderNormalizer.prototype.normalize = function (elements, origin) {
        var startOrderEvent = new StartOrderEvent_1.default(origin);
        var offCatalogClass = ".folder-offcatalog:has(.remove-part-from-cart),.folder-offcatalog:has(.choice-piece:checked)";
        var amplitudeDivs = $(offCatalogClass).find(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
        });
        var choosenClass = "td:has(.remove-part-from-cart),td:has(.choice-piece:checked)";
        amplitudeDivs = $(choosenClass).find(constants_1.AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
        });
        return startOrderEvent;
    };
    StartOrderForFolderNormalizer.prototype.support = function (element, origin) {
        return constants_1.IMMEDIATE_ORDER === origin;
    };
    return StartOrderForFolderNormalizer;
}());
exports.default = StartOrderForFolderNormalizer;
