"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var AddToCartFromFolderNormalizer_1 = require("./AddToCartFromFolderNormalizer");
var AddToCartFromQuoteNormalizer_1 = require("./AddToCartFromQuoteNormalizer");
var AddToCartNormalizer_1 = require("./AddToCartNormalizer");
var AbstractNormalizersResolver_1 = require("../../core/AbstractNormalizersResolver");
var AddToCartNormalizersResolver = /** @class */ (function (_super) {
    __extends(AddToCartNormalizersResolver, _super);
    function AddToCartNormalizersResolver() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddToCartNormalizersResolver.prototype.register = function () {
        return [
            new AddToCartFromFolderNormalizer_1.default(),
            new AddToCartFromQuoteNormalizer_1.default(),
            new AddToCartNormalizer_1.default(),
        ];
    };
    return AddToCartNormalizersResolver;
}(AbstractNormalizersResolver_1.default));
exports.default = AddToCartNormalizersResolver;
