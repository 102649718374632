"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var PaintStartSearchNormalizer_1 = require("./PaintStartSearchNormalizer");
var Flash_1 = require("../../Flash");
var ConsumablesStartSearchNormalizer = /** @class */ (function (_super) {
    __extends(ConsumablesStartSearchNormalizer, _super);
    function ConsumablesStartSearchNormalizer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConsumablesStartSearchNormalizer.prototype.normalize = function (element, origin) {
        var startSearchEvent = _super.prototype.normalize.call(this, element, origin);
        startSearchEvent.catalog = 'consommables';
        Flash_1.default.setConsumableCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    };
    ConsumablesStartSearchNormalizer.prototype.support = function (element, origin) {
        return 'consumable' === origin;
    };
    return ConsumablesStartSearchNormalizer;
}(PaintStartSearchNormalizer_1.default));
exports.default = ConsumablesStartSearchNormalizer;
