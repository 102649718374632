"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewCampaignEvent_1 = require("./ViewCampaignEvent");
var ViewCampaignNormalizer = /** @class */ (function () {
    function ViewCampaignNormalizer() {
    }
    ViewCampaignNormalizer.prototype.normalize = function (elements, origin) {
        var promoJson = $(elements).data('promo');
        return new ViewCampaignEvent_1.default(promoJson);
    };
    ViewCampaignNormalizer.prototype.support = function (elements, origin) {
        return true;
    };
    return ViewCampaignNormalizer;
}());
exports.default = ViewCampaignNormalizer;
