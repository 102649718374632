"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var AddToCartEvent_1 = require("./AddToCartEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var AddToCartFromFolderNormalizer = /** @class */ (function () {
    function AddToCartFromFolderNormalizer() {
    }
    AddToCartFromFolderNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default(context.origin);
        $('td .folder-chosen').each(function (index, value) {
            var amplitudeDiv = $(value).closest('td').find(constants_1.AMPLITUDE_CLASS);
            if (1 === amplitudeDiv.length) {
                var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
                addToCartEvent.productCollection.addProduct(product);
            }
        });
        var offCatalogClass = ".ref-oe-container.bloc-reference.folder-offcatalog";
        $(offCatalogClass + ' .folder-chosen').each(function (index, value) {
            var amplitudeDiv = $(value).closest(offCatalogClass).find(constants_1.AMPLITUDE_CLASS);
            if (1 === amplitudeDiv.length) {
                var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
                addToCartEvent.productCollection.addProduct(product);
            }
        });
        addToCartEvent.status = context.status;
        return addToCartEvent;
    };
    AddToCartFromFolderNormalizer.prototype.support = function (element, context) {
        return constants_1.FOLDER_COST === context.origin;
    };
    return AddToCartFromFolderNormalizer;
}());
exports.default = AddToCartFromFolderNormalizer;
