"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var RemoveFromCartNormalizer = /** @class */ (function () {
    function RemoveFromCartNormalizer() {
    }
    RemoveFromCartNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default(origin);
        $(elements).each(function (index, element) {
            var parentDiv = $(element).closest('.lineItem,td');
            if (0 === parentDiv.length) {
                return;
            }
            var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
            if (0 === amplitudeDiv.length) {
                return;
            }
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
            // ProductAttributes.updateLayerAvailabilityForAmplitude(parentDiv, amplitudeDiv).done(() => {
            //     const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            //     removeFromCartEvent.productCollection.addProduct(product);
            // });
        });
        return removeFromCartEvent;
    };
    RemoveFromCartNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return RemoveFromCartNormalizer;
}());
exports.default = RemoveFromCartNormalizer;
