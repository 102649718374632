"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Flash_1 = require("./Flash");
var Context = /** @class */ (function () {
    function Context() {
    }
    Context.setLastConsumablesPaintSubfamily = function (value) {
        Flash_1.default.set('lastConsumablesPaintSubfamily', value);
    };
    Context.getLastConsumablesPaintSubfamily = function () {
        return Flash_1.default.get('lastConsumablesPaintSubfamily');
    };
    return Context;
}());
exports.default = Context;
