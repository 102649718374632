"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var EditPartQuantityEvent_1 = require("./EditPartQuantityEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var EditPartQuantityNormalizer = /** @class */ (function () {
    function EditPartQuantityNormalizer() {
    }
    EditPartQuantityNormalizer.prototype.normalize = function (element, origin) {
        var editPartQuantityEvent = new EditPartQuantityEvent_1.default(origin);
        var parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return editPartQuantityEvent;
        }
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return editPartQuantityEvent;
        }
        var quantity = $(element).val().toString();
        amplitudeDiv.attr('data-quantity', quantity).data('quantity', quantity);
        var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
        editPartQuantityEvent.productCollection.addProduct(product);
        return editPartQuantityEvent;
    };
    EditPartQuantityNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return EditPartQuantityNormalizer;
}());
exports.default = EditPartQuantityNormalizer;
