"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AddPartToQuotationEvent_1 = require("./AddPartToQuotationEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var AddPartToQuotationNormalizer = /** @class */ (function () {
    function AddPartToQuotationNormalizer() {
    }
    AddPartToQuotationNormalizer.prototype.normalize = function (elements, origin) {
        var productString = elements.attr('data-amplitude');
        var product = ProductFactory_1.ProductFactory.createFromJsonString(productString);
        var addPartToQuotationEvent = new AddPartToQuotationEvent_1.default(origin);
        addPartToQuotationEvent.productCollection.addProduct(product);
        return addPartToQuotationEvent;
    };
    AddPartToQuotationNormalizer.prototype.support = function (elements, origin) {
        return true;
    };
    return AddPartToQuotationNormalizer;
}());
exports.default = AddPartToQuotationNormalizer;
