"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var RemoveFromCartDetailsNormalizer = /** @class */ (function () {
    function RemoveFromCartDetailsNormalizer() {
    }
    RemoveFromCartDetailsNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default(origin);
        var parentDiv = $(elements).closest('article');
        if (1 === parentDiv.length) {
            var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
            return removeFromCartEvent;
        }
        var amplitudeDivs = $('article ' + constants_1.AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
        });
        return removeFromCartEvent;
    };
    RemoveFromCartDetailsNormalizer.prototype.support = function (element, origin) {
        return 'cart_details' === origin;
    };
    return RemoveFromCartDetailsNormalizer;
}());
exports.default = RemoveFromCartDetailsNormalizer;
