"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventDtoTransformer = void 0;
var resultsNotFoundEvent_1 = require("./resultsNotFoundEvent");
var EventDtoTransformer = /** @class */ (function () {
    function EventDtoTransformer() {
    }
    EventDtoTransformer.transformFromStartSearchDto = function (startSearchEvent) {
        var resultsNotFoundEvent = new resultsNotFoundEvent_1.ResultsNotFoundEvent();
        resultsNotFoundEvent.catalog = startSearchEvent.catalog;
        resultsNotFoundEvent.immatInput = startSearchEvent.immatInput;
        resultsNotFoundEvent.searchType = startSearchEvent.searchType;
        resultsNotFoundEvent.searchTerms = startSearchEvent.searchTerms;
        resultsNotFoundEvent.markerName = startSearchEvent.markerName;
        resultsNotFoundEvent.referenceType = startSearchEvent.referenceType;
        resultsNotFoundEvent.referenceCode = startSearchEvent.referenceCode;
        resultsNotFoundEvent.searchMarker = startSearchEvent.searchMarker;
        resultsNotFoundEvent.consumablesPaintSubfamily = startSearchEvent.consumablesPaintSubfamily;
        return resultsNotFoundEvent;
    };
    return EventDtoTransformer;
}());
exports.EventDtoTransformer = EventDtoTransformer;
