"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ApplyFiltersEvent = /** @class */ (function () {
    function ApplyFiltersEvent() {
        this.catalog = '';
        this.filtersCategories = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
        this.manufacturerBrand = '';
        this.filtersValues = '';
    }
    ApplyFiltersEvent.prototype.export = function () {
        return {
            catalog: this.catalog,
            filters_categories: this.filtersCategories,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
            manufacturer_brand: this.manufacturerBrand,
            filters_values: this.filtersValues,
        };
    };
    return ApplyFiltersEvent;
}());
exports.default = ApplyFiltersEvent;
