"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemAttributes = void 0;
var constants_1 = require("../../../constants");
/**
 * update attributes after ajax response
 */
var ItemAttributes = /** @class */ (function () {
    function ItemAttributes() {
    }
    ItemAttributes.fromPrices = function (item, values) {
        var price = values.sale_price !== undefined ? values.sale_price.replace(',', '.') : "--.--";
        var priceTarif = values.purchase_price !== undefined ? values.purchase_price.replace(',', '.') : "--.--";
        var availability = values.availability !== undefined ? values.availability : "--";
        var quantity = values.quantity;
        var pricePromo = values.REMISE !== undefined ? values.REMISE.replace(',', '.') : "--.--";
        var codref = $(item).data('codref');
        var gaDiv = $(item).find('#ga-' + codref);
        $(gaDiv).attr(constants_1.ATTR_PRICE, price).data(constants_1.DATA_PRICE, price);
        $(gaDiv).attr(constants_1.ATTR_PRICE_TARIF, priceTarif).data(constants_1.DATA_PRICE_TARIF, priceTarif);
        $(gaDiv).attr(constants_1.ATTR_QUANTITY, quantity).data(constants_1.DATA_QUANTITY, quantity);
        $(gaDiv).attr(constants_1.ATTR_ITEM_AVAILABILITY, availability).data(constants_1.DATA_ITEM_AVAILABILITY, availability);
        $(gaDiv).attr(constants_1.ATTR_PRICE_PROMO, pricePromo).data(constants_1.DATA_PRICE_PROMO, pricePromo);
    };
    ItemAttributes.fromCheckDispo = function (parent, result) {
        var delaiSimplifie = result.DELAI_SIMPLIFIE !== undefined ? result.DELAI_SIMPLIFIE : "--";
        var parentDiv = $(parent).closest(':has(.ga)');
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        $(gaDiv).attr(constants_1.ATTR_ITEM_AVAILABILITY, delaiSimplifie).data(constants_1.DATA_ITEM_AVAILABILITY, delaiSimplifie);
        var quantity = $(parent).val().toString();
        $(gaDiv).attr('data-quantity', quantity).data('quantity', quantity);
    };
    ItemAttributes.fromLoadPrice = function (item, value) {
        var price = value.PVHT !== undefined ? value.PVHT.replace(',', '.') : "--.--";
        var priceTarif = value.PAHT !== undefined ? value.PAHT.replace(',', '.') : "--.--";
        var availability = value.DELAI_SIMPLIFIE !== undefined ? value.DELAI_SIMPLIFIE : "--";
        var quantity = value.QUANTITE;
        var pricePromo = value.REMISE !== undefined ? value.REMISE.replace(',', '.') : "--.--";
        var parentDiv = $(item).closest(':has(.ga)');
        var gaDiv = $(parentDiv).find(constants_1.GA_CLASS);
        $(gaDiv).attr(constants_1.ATTR_PRICE, price).data(constants_1.DATA_PRICE, price);
        $(gaDiv).attr(constants_1.ATTR_PRICE_TARIF, priceTarif).data(constants_1.DATA_PRICE_TARIF, priceTarif);
        $(gaDiv).attr(constants_1.ATTR_QUANTITY, quantity).data(constants_1.DATA_QUANTITY, quantity);
        $(gaDiv).attr(constants_1.ATTR_ITEM_AVAILABILITY, availability).data(constants_1.DATA_ITEM_AVAILABILITY, availability);
        $(gaDiv).attr(constants_1.ATTR_PRICE_PROMO, pricePromo).data(constants_1.DATA_PRICE_PROMO, pricePromo);
    };
    ItemAttributes.fromQuoteGetPrice = function (element, result) {
        var gaDiv = $(element).closest('td').find('.ga');
        if (1 === $(gaDiv).length) {
            $(gaDiv).attr(constants_1.ATTR_ITEM_AVAILABILITY, result.delai_simplifie).data(constants_1.DATA_ITEM_AVAILABILITY, result.delai_simplifie);
        }
    };
    ItemAttributes.fromLayerGetPrice = function (gaDiv, result) {
        if (1 === $(gaDiv).length) {
            $(gaDiv).attr(constants_1.ATTR_ITEM_AVAILABILITY, result.delai_simplifie).data(constants_1.DATA_ITEM_AVAILABILITY, result.delai_simplifie);
        }
    };
    ItemAttributes.updateLayerAvailabilityForGoogleAnalytics = function (element, gaDiv) {
        var hasQuotationAccess = $('#quotation_access').val();
        if (hasQuotationAccess == 1) {
            var data = {
                codequ: $(element).attr('data-equip'),
                refeq: $(element).attr('data-refeq'),
                typeligne: $(element).attr('data-typeline'),
                qte: $(gaDiv).attr('data-quantity'),
                codfam: $(gaDiv).attr('data-cod-fam')
            };
            return $.ajax({
                url: Routing.generate('autossimo_quotes_get_availabilty'),
                type: "POST",
                data: data,
                async: false,
                success: function (result) {
                    ItemAttributes.fromLayerGetPrice(gaDiv, result);
                }
            });
        }
    };
    return ItemAttributes;
}());
exports.ItemAttributes = ItemAttributes;
