"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var ViewCartDetailsEvent_1 = require("./ViewCartDetailsEvent");
var ViewCartDetailsNormalizer = /** @class */ (function () {
    function ViewCartDetailsNormalizer() {
    }
    ViewCartDetailsNormalizer.prototype.normalize = function (elements, origin) {
        var viewCartDetails = new ViewCartDetailsEvent_1.default(origin);
        var amplitudeDivs = $('.section_cart--body ' + constants_1.AMPLITUDE_CLASS);
        amplitudeDivs.each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewCartDetails.productCollection.addProduct(product);
        });
        return viewCartDetails;
    };
    ViewCartDetailsNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return ViewCartDetailsNormalizer;
}());
exports.default = ViewCartDetailsNormalizer;
