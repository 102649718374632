"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var ViewItemDetailsEvent_1 = require("./ViewItemDetailsEvent");
var ViewItemDetailsProductButtonNormalizer = /** @class */ (function () {
    function ViewItemDetailsProductButtonNormalizer() {
    }
    ViewItemDetailsProductButtonNormalizer.prototype.normalize = function (element, origin) {
        var viewItemDetailsEvent = new ViewItemDetailsEvent_1.default(origin);
        var parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return viewItemDetailsEvent;
        }
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return viewItemDetailsEvent;
        }
        var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
        viewItemDetailsEvent.catalog = product.catalog;
        viewItemDetailsEvent.productCollection.addProduct(product);
        return viewItemDetailsEvent;
    };
    ViewItemDetailsProductButtonNormalizer.prototype.support = function (element, origin) {
        return true;
    };
    return ViewItemDetailsProductButtonNormalizer;
}());
exports.default = ViewItemDetailsProductButtonNormalizer;
