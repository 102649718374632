"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchDataBuilder_1 = require("./startSearch/StartSearchDataBuilder");
var ApplyFiltersDataBuilder_1 = require("./applyFilters/ApplyFiltersDataBuilder");
var AmplitudeProcessor_1 = require("./AmplitudeProcessor");
var manage_ga_1 = require("../../manage-ga");
var ViewVehicleResultsDataBuilder_1 = require("./viewVehicleResults/ViewVehicleResultsDataBuilder");
var ViewItemResultsDataBuilder_1 = require("./viewItemResults/ViewItemResultsDataBuilder");
var RemoveFromCartDataBuilder_1 = require("./removeFromCart/RemoveFromCartDataBuilder");
var constants_1 = require("../constants");
var AddToCartDataBuilder_1 = require("./addToCart/AddToCartDataBuilder");
var StartAddToQuotationDataBuilder_1 = require("./startAddToQuotation/StartAddToQuotationDataBuilder");
var AddPartToQuotationDataBuilder_1 = require("./addPartToQuotation/AddPartToQuotationDataBuilder");
var ViewVehicleTechnicalDataBuilder_1 = require("./viewVehicleTechnicalData/ViewVehicleTechnicalDataBuilder");
var ViewLaborTimeBuilder_1 = require("./viewLaborTime/ViewLaborTimeBuilder");
var ViewCartLayoutDataBuilder_1 = require("./viewCartLayout/ViewCartLayoutDataBuilder");
var ViewCartDetailsDataBuilder_1 = require("./viewCartDetails/ViewCartDetailsDataBuilder");
var CompleteOrderBuilder_1 = require("./completeOrder/CompleteOrderBuilder");
var StartOrderDataBuilder_1 = require("./startOrder/StartOrderDataBuilder");
var EditPartQuantityDataBuilder_1 = require("./editPartQuantity/EditPartQuantityDataBuilder");
var FeatureFlag_1 = require("../FeatureFlag");
var ResultsNotFoundDataBuilder_1 = require("./resultsNotFound/ResultsNotFoundDataBuilder");
var IdentifyUserDataBuilder_1 = require("./identifyUser/IdentifyUserDataBuilder");
var utilities_1 = require("../utilities");
var ViewItemDetailsDataBuilder_1 = require("./viewItemDetails/ViewItemDetailsDataBuilder");
var ViewItemPictureDataBuilder_1 = require("./viewItemPicture/ViewItemPictureDataBuilder");
var DisplayIamForOemDataBuilder_1 = require("./displayIamForOem/DisplayIamForOemDataBuilder");
var ViewMatchingTiresDataBuilder_1 = require("./viewMatchingTires/ViewMatchingTiresDataBuilder");
var ViewCampaignDataBuilder_1 = require("./viewCampaign/ViewCampaignDataBuilder");
var ViewCampaignDetailDataBuilder_1 = require("./viewCampaignDetail/ViewCampaignDetailDataBuilder");
var PdfQuotationBuilder_1 = require("./pdfQuotation/PdfQuotationBuilder");
var EventHandler = /** @class */ (function () {
    function EventHandler() {
    }
    EventHandler.identifyUser = function () {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = IdentifyUserDataBuilder_1.default.create();
        var dto = builder.build((0, manage_ga_1.getDataUser)((0, utilities_1.getGADataBlock)()), null);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.identify(dto);
    };
    EventHandler.startSearch = function (element, catalog) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = StartSearchDataBuilder_1.default.create();
        var dto = builder.build(element, catalog);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('start_search', dto.export());
    };
    EventHandler.handleConsumableAndPaintEvent = function () {
        var container = $('#search-paints-block');
        if (0 === container.length) {
            container = $('#search-consumables-block');
        }
        if ($('.peinturesnav').hasClass('active')) {
            EventHandler.startSearch(container, 'paints');
        }
        else if ($('.consommablesnav').hasClass('active')) {
            EventHandler.startSearch(container, 'consumable');
        }
        else {
            console.warn('Something is wrong with consumable and paint tabs');
        }
    };
    EventHandler.resultsNotFound = function (element, catalog) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ResultsNotFoundDataBuilder_1.default.create();
        var dto = builder.build(element, catalog);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('results_not_found', dto.export());
    };
    EventHandler.viewVehicleResults = function (blockData, category) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewVehicleResultsDataBuilder_1.default.create();
        var dto = builder.build(blockData, category);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_vehicle_results', dto.export());
    };
    EventHandler.viewItemResults = function (blockData, category) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewItemResultsDataBuilder_1.default.create();
        var dto = builder.build(blockData, category);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_item_results', dto.export());
    };
    EventHandler.viewMatchingResults = function (blockData, context) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewMatchingTiresDataBuilder_1.default.create();
        var dto = builder.build(blockData, context);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_matching_tires', dto.export());
    };
    EventHandler.applyFilters = function (element, context) {
        var _a, _b;
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ApplyFiltersDataBuilder_1.default.create();
        var catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : (0, manage_ga_1.getCategory)(constants_1.PATH);
        var dto = builder.build(element, {
            filterType: (_b = context.filterType) !== null && _b !== void 0 ? _b : null,
            catalog: catalog,
        });
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('apply_filters', dto.export());
    };
    // cart
    EventHandler.removeFromCart = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = RemoveFromCartDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('remove_from_cart', dto.export());
    };
    // cart
    EventHandler.addToCart = function (element, origin, status) {
        if (status === void 0) { status = false; }
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = AddToCartDataBuilder_1.default.create();
        var dto = builder.build(element, { origin: origin, status: status });
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('add_to_cart', dto.export());
    };
    // cart
    EventHandler.startAddToQuotation = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = StartAddToQuotationDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('start_add_to_quotation', dto.export());
    };
    // cart
    EventHandler.addPartToQuotation = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = AddPartToQuotationDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('add_part_to_quotation', dto.export());
    };
    EventHandler.vehicleTechnicalData = function (origin, vehicleDataBlock) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewVehicleTechnicalDataBuilder_1.default.create();
        var dto = builder.build({ vehicleDataBlock: vehicleDataBlock }, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_vehicle_technical_data', dto.export());
    };
    EventHandler.viewLaborTime = function () {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewLaborTimeBuilder_1.default.create();
        var dto = builder.build(null, null);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_labor_time', dto.export());
    };
    // cart
    EventHandler.viewCartLayout = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewCartLayoutDataBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_cart_layout', dto.export());
    };
    // cart
    EventHandler.viewCartDetails = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        if (constants_1.PATH.indexOf('/panier') >= 0) {
            var builder = ViewCartDetailsDataBuilder_1.default.create();
            var dto = builder.build(element, origin);
            if (null === dto) {
                return;
            }
            AmplitudeProcessor_1.AmplitudeProcessor.track('view_cart_details', dto.export());
        }
    };
    // cart
    EventHandler.completeOrder = function (element, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = CompleteOrderBuilder_1.default.create();
        var dto = builder.build(element, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('complete_order', dto.export());
    };
    // cart
    EventHandler.startOrder = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = StartOrderDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('start_order', dto.export());
    };
    // cart
    EventHandler.editPartQuantity = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = EditPartQuantityDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('edit_part_quantity', dto.export());
    };
    // Fiche produit AD-9888
    EventHandler.viewItemDetails = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewItemDetailsDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_item_details', dto.export());
    };
    EventHandler.viewItemPicture = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewItemPictureDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_item_picture', dto.export());
    };
    // AD-10073 Display_iam_for_oe
    EventHandler.displayIamForOem = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = DisplayIamForOemDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('display_iam_for_oem', dto.export());
    };
    EventHandler.viewCampaignDetail = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewCampaignDetailDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_campaign_details2', dto.export());
    };
    EventHandler.viewCampaign = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = ViewCampaignDataBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('view_campaign', dto.export());
    };
    EventHandler.pdfQuotation = function (elements, origin) {
        if (false === FeatureFlag_1.FeatureFlag.isEnabled()) {
            return;
        }
        var builder = PdfQuotationBuilder_1.default.create();
        var dto = builder.build(elements, origin);
        if (null === dto) {
            return;
        }
        AmplitudeProcessor_1.AmplitudeProcessor.track('pdf_quotation', dto.export());
    };
    EventHandler.comparativeQuotation = function (products) {
        AmplitudeProcessor_1.AmplitudeProcessor.track('compare_quotation', {
            product: products
        });
    };
    return EventHandler;
}());
exports.default = EventHandler;
