"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ViewCampaignEvent = /** @class */ (function () {
    function ViewCampaignEvent(campaign) {
        this.campaign = campaign;
    }
    ViewCampaignEvent.prototype.export = function () {
        return {
            campaign: this.campaign,
        };
    };
    return ViewCampaignEvent;
}());
exports.default = ViewCampaignEvent;
