"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var MatchingResultsEvent = /** @class */ (function () {
    function MatchingResultsEvent() {
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    MatchingResultsEvent.prototype.export = function () {
        return {
            is_loading: this.isLoading,
            products: this.productCollection.export(),
        };
    };
    return MatchingResultsEvent;
}());
exports.default = MatchingResultsEvent;
