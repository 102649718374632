"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var AddToCartEvent_1 = require("./AddToCartEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var AddToCartNormalizer = /** @class */ (function () {
    function AddToCartNormalizer() {
    }
    AddToCartNormalizer.prototype.normalize = function (elements, context) {
        var addToCartEvent = new AddToCartEvent_1.default(context.origin);
        var parentDiv = $(elements).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return addToCartEvent;
        }
        var amplitudeDiv = $(parentDiv).find(constants_1.AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return addToCartEvent;
        }
        var product = ProductFactory_1.ProductFactory.createFromDataAttribute(amplitudeDiv);
        addToCartEvent.productCollection.addProduct(product);
        addToCartEvent.status = context.status;
        return addToCartEvent;
    };
    AddToCartNormalizer.prototype.support = function (element, context) {
        return true;
    };
    return AddToCartNormalizer;
}());
exports.default = AddToCartNormalizer;
