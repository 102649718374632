"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../constants");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var RemoveFromCartEvent_1 = require("./RemoveFromCartEvent");
var RemoveFromFolderCostNormalizer = /** @class */ (function () {
    function RemoveFromFolderCostNormalizer() {
    }
    RemoveFromFolderCostNormalizer.prototype.normalize = function (elements, origin) {
        var removeFromCartEvent = new RemoveFromCartEvent_1.default(origin);
        var amplitudeDivs = $(elements)
            .closest('.folder-offcatalog,td,#popinFicheProduit')
            .find("div[id^='amplitude-']");
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
        });
        return removeFromCartEvent;
    };
    RemoveFromFolderCostNormalizer.prototype.support = function (element, origin) {
        return constants_1.FOLDER_COST === origin;
    };
    return RemoveFromFolderCostNormalizer;
}());
exports.default = RemoveFromFolderCostNormalizer;
