"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var Flash_1 = require("../../Flash");
var ViewItemResultsEvent_1 = require("./ViewItemResultsEvent");
var Context_1 = require("../../Context");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var ConsumableViewItemResultsNormalizer = /** @class */ (function () {
    function ConsumableViewItemResultsNormalizer() {
    }
    ConsumableViewItemResultsNormalizer.prototype.normalize = function (blockDataLayer, catalog) {
        var _a, _b, _c;
        var origin = (_a = Flash_1.default.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : ''; // à vérifier
        var viewItemResultsEvent = new ViewItemResultsEvent_1.default();
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = (0, utilities_1.checkCons)(catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = '';
        viewItemResultsEvent.subfamily = '';
        viewItemResultsEvent.markerName = '';
        viewItemResultsEvent.filtersApplied = Flash_1.default.isFiltredViewItemResult();
        viewItemResultsEvent.totalIamResults = (_c = $('.nbr-item > strong').text()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.totalOemResults = '';
        viewItemResultsEvent.consumablesPaintSubfamily = Context_1.default.getLastConsumablesPaintSubfamily();
        var amplitudeDivs = $('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    };
    ConsumableViewItemResultsNormalizer.prototype.support = function (element, catalog) {
        return 'consumables' === catalog || 'peintures' === catalog || 'chaine' === catalog;
    };
    return ConsumableViewItemResultsNormalizer;
}());
exports.default = ConsumableViewItemResultsNormalizer;
