"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaintStartSearchNormalizer_1 = require("../startSearch/PaintStartSearchNormalizer");
var EventDtoTransformer_1 = require("./EventDtoTransformer");
var Flash_1 = require("../../Flash");
var PaintResultsNotFoundNormalizer = /** @class */ (function () {
    function PaintResultsNotFoundNormalizer() {
        this.paintStartSearchNormalizer = new PaintStartSearchNormalizer_1.default();
    }
    PaintResultsNotFoundNormalizer.prototype.normalize = function (element, category) {
        var startSearchEvent = Flash_1.default.getPaintCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.paintStartSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer_1.EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    };
    PaintResultsNotFoundNormalizer.prototype.support = function (element, category) {
        return this.paintStartSearchNormalizer.support(element, category);
    };
    return PaintResultsNotFoundNormalizer;
}());
exports.default = PaintResultsNotFoundNormalizer;
