"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventDtoTransformer_1 = require("./EventDtoTransformer");
var ConsumablesStartSearchNormalizer_1 = require("../startSearch/ConsumablesStartSearchNormalizer");
var Flash_1 = require("../../Flash");
var ConsumableResultsNotFoundNormalizer = /** @class */ (function () {
    function ConsumableResultsNotFoundNormalizer() {
        this.consumablesStartSearchNormalizer = new ConsumablesStartSearchNormalizer_1.default();
    }
    ConsumableResultsNotFoundNormalizer.prototype.normalize = function (element, category) {
        var startSearchEvent = Flash_1.default.getConsumableCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.consumablesStartSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer_1.EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    };
    ConsumableResultsNotFoundNormalizer.prototype.support = function (element, category) {
        return this.consumablesStartSearchNormalizer.support(element, category);
    };
    return ConsumableResultsNotFoundNormalizer;
}());
exports.default = ConsumableResultsNotFoundNormalizer;
