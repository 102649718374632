"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../../utilities");
var Flash_1 = require("../../Flash");
var ViewItemResultsEvent_1 = require("./ViewItemResultsEvent");
var ProductFactory_1 = require("../dto/product/ProductFactory");
var PieceViewItemResultsNormalizer = /** @class */ (function () {
    function PieceViewItemResultsNormalizer() {
    }
    PieceViewItemResultsNormalizer.prototype.normalize = function (blockDataLayer, catalog) {
        var _a, _b, _c, _d, _e, _f, _g;
        var origin = (_a = Flash_1.default.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : '';
        var viewItemResultsEvent = new ViewItemResultsEvent_1.default();
        var totalIamResults = $('.nb-ref-equi-total');
        var totalOemResults = $('.nb-ref-origine');
        if (0 === totalOemResults.length) {
            totalOemResults = $('.nb-ref-oem');
        }
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = (0, utilities_1.checkCons)(catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = (_c = (0, utilities_1.getFamily)()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.subfamily = (_d = (0, utilities_1.getSubFamily)()) !== null && _d !== void 0 ? _d : '';
        viewItemResultsEvent.markerName = (_e = (0, utilities_1.getLibRep)()) !== null && _e !== void 0 ? _e : '';
        viewItemResultsEvent.filtersApplied = this.isFilterApplied();
        viewItemResultsEvent.totalIamResults = (_f = totalIamResults.text()) !== null && _f !== void 0 ? _f : '';
        viewItemResultsEvent.totalOemResults = (_g = totalOemResults.text()) !== null && _g !== void 0 ? _g : '';
        var amplitudeDivs = blockDataLayer.find('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            var product = ProductFactory_1.ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    };
    PieceViewItemResultsNormalizer.prototype.support = function (element, catalog) {
        return 'pieces' === catalog;
    };
    PieceViewItemResultsNormalizer.prototype.isFilterApplied = function () {
        var selectedEquip = $('#selectEquip').val();
        return $('.criteres').hasClass('active') ||
            (null !== selectedEquip && undefined !== selectedEquip && '' !== selectedEquip);
    };
    return PieceViewItemResultsNormalizer;
}());
exports.default = PieceViewItemResultsNormalizer;
