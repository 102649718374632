"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StartSearchEvent_1 = require("./StartSearchEvent");
var SearchTypeMapper_1 = require("./SearchTypeMapper");
var Flash_1 = require("../../Flash");
var Context_1 = require("../../Context");
var PaintStartSearchNormalizer = /** @class */ (function () {
    function PaintStartSearchNormalizer() {
    }
    PaintStartSearchNormalizer.prototype.normalize = function (element, origin) {
        var startSearchEvent = new StartSearchEvent_1.default();
        var searchType = element.find('#select-type').text();
        startSearchEvent.catalog = 'peintures';
        if ('Recherche par mots-clés' === searchType) {
            startSearchEvent.searchTerms = element.find('input.search-consumables').val();
        }
        else {
            // recherche par arboressence
            var searchTerms = [];
            var subfamily = element.find('#selected-subfamily > span').text().trim();
            searchTerms.push(element.find('#selected-heading > span').text().trim());
            searchTerms.push(element.find('#selected-family > span').text().trim());
            searchTerms.push(subfamily);
            startSearchEvent.searchTerms = searchTerms.join(' | ');
            startSearchEvent.consumablesPaintSubfamily = subfamily;
            Context_1.default.setLastConsumablesPaintSubfamily(startSearchEvent.consumablesPaintSubfamily);
        }
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        startSearchEvent.searchType = SearchTypeMapper_1.default.get(searchType);
        Flash_1.default.setPaintCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    };
    PaintStartSearchNormalizer.prototype.support = function (element, origin) {
        return 'paints' === origin;
    };
    return PaintStartSearchNormalizer;
}());
exports.default = PaintStartSearchNormalizer;
