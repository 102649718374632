"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var ViewItemDetailsEvent = /** @class */ (function () {
    function ViewItemDetailsEvent(origin) {
        this.origin = origin;
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    ViewItemDetailsEvent.prototype.export = function () {
        return {
            view_item_details_origin: this.origin,
            catalog: this.catalog,
            products: this.productCollection.export(),
        };
    };
    return ViewItemDetailsEvent;
}());
exports.default = ViewItemDetailsEvent;
