"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductCollection_1 = require("../dto/product/ProductCollection");
var ViewItemResultsEvent = /** @class */ (function () {
    function ViewItemResultsEvent() {
        this.origin = '';
        this.catalog = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
        this.filtersApplied = false;
        this.totalIamResults = '';
        this.totalOemResults = '';
        this.consumablesPaintSubfamily = '';
        this.productCollection = new ProductCollection_1.ProductCollection();
    }
    ViewItemResultsEvent.prototype.export = function () {
        return {
            origin: this.origin,
            catalog: this.catalog,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
            filters_applied: this.filtersApplied,
            total_iam_results: this.totalIamResults,
            total_oem_results: this.totalOemResults,
            consumables_paint_subfamily: this.consumablesPaintSubfamily,
            products: this.productCollection.export(),
        };
    };
    return ViewItemResultsEvent;
}());
exports.default = ViewItemResultsEvent;
